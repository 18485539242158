.card{
    min-height: 100px;
    width: 97%;
    border-radius: 8px !important;
}

.image_wrapper{
    width: 150px;
    height: 180px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
    margin-left: auto;
    margin-right: auto;
}

.img{
    width: 100%;
}

.product_wording{
    height: 60px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
}

.product_pricing{
    padding: 15px;
}