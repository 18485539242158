.security {
    background-color: #f8f8f8;
    font-size: 18px;
    padding: 25px;
}

.button {
    border-radius: 100px;
    padding: 5px 30px;
}

.form {
    width: 650px;
}

@media screen and (max-width: 800px) {
    .form {
        width: 100%;
    }

    .button {
        width: 100%;
        display: block;
    }
}
