.requestQuotation {
    background-color: #f8f8f8;
    font-size: 18px;
    padding: 25px;
    width: 640px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #5552;
    border-radius: 2px;
}

.q_wrapper {
    background-color: #f4f4f0;
    padding: 20px;
}
.q_wrapper label {
    display: inline-block;
    font-size: 15px;
    padding-bottom: 8px;
    color: #333;
    font-weight: 500;
}
.q_nav {
    width: 640px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 0;
}

.q_nav a {
    display: inline-block;
    padding-top: 10px;
    font-size: 17px;
}

.q_header h3 {
    font-size: 22px;
    font-weight: 600;
    color: #222;
}

.q_header p {
    font-size: 15px;
    color: #444;
    font-weight: 400;
}

@media screen and (max-width: 650px) {
    .requestQuotation {
        width: 480px;
    }
}

@media screen and (max-width: 480px) {
    .requestQuotation {
        width: 320px;
    }

    .header {
         font-size: 22px;
        font-weight: 600;
    }
}

@media screen and (max-width: 360px) {
    .requestQuotation {
        width: 280px;
    }
}

@media screen and (max-width: 300px) {
    .requestQuotation {
        width: 240px;
    }
}
