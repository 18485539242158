.overlay {
  position: fixed;
  top: 130px;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 100;

  @media screen and (max-width: 990px) {
    top: 115px;
  }

  @media screen and (max-width: 600px) {
    top: 50px;
  }
}