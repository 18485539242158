.mainHeader {
  background-image: url("../../assets/images/webp/flu-shot.webp");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center -100px;
  background-attachment: fixed;
}

@media (max-width: 1300px) {
  .mainHeader {
    background-attachment: scroll;
  }
}

@media (max-width: 992px) {
  .mainHeader {
    background-size: 100%;
  }
}

@media (max-width: 600px) {
  .mainHeader {
    background-size: 120%;
    background-position: center;
    background-attachment: scroll;
  }
}

.flu_shot_bg {
  background-color: rgba(108, 212, 212, 0.2);
  background-image: url("../../assets/images/webp/flu-shot.webp");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 52%;
  border-radius: 10px;
  margin-top: 25px;
}

.flu_shot_header {
  background-color: #add5;
  padding: 5%;
  border-radius: 10px;
}

.flu_shot_header h1 {
  font-size: 52px;
  color: #222;
}

.flu_shot_header p {
  font-size: 20px;
  color: #555;
}

.flu_shot_cont {
  background-color: #add1;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0px;
}

.flu_shot_cont p {
  font-size: 17px;
  color: #333;
}

.vac_bg {
  background-color: #f8f8f8;
  padding: 10px;
  margin: 30px 0px;
  border-radius: 10px;
}

.vac_header {
  padding: 10px;
  text-align: center;
}

.vac_header h4 {
  font-weight: 600;
  font-size: 24px;
  color: #222;
}

.vac_header p {
  font-size: 17px;
  color: #444;
}

.vac_card {
  padding: 10px;
  text-align: center;
}

.vac_img {
  padding: 5px;
  text-align: center;
  min-height: 100px;
  overflow: hidden;
}

.vac_img img {
  max-width: 90px;
  width: 100%;
  border-radius: 2%;
}

.vac_cont {
  padding: 10px 0px;
}

.vac_cont h5 {
  margin: auto;
  font-size: 17px;
  font-weight: 600;
  color: #222;
}

.vac_cont p {
  color: #444;
  font-size: 16px;
}

.section {
}

.steps {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 2rem;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
}

.step h5 {
  font-size: 78px;
  color: #c4d600;
  margin-bottom: 0;
}

.step .subtitle {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  line-height: 1.1;
}

.step p {
  text-align: center;
}

/* Mobile view */
@media (max-width: 992px) {
  .flu_shot_bg {
    background-size: 60%;
  }

  .flu_shot_header {
    padding: 3% 15px;
    width: 40%;
    border-radius: 10px 0 0 10px;
  }

  .flu_shot_header h1 {
    font-size: 24px;
    font-weight: 600;
  }

  .flu_shot_header p {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .flu_shot_bg {
    background-position: top;
    background-size: 100%;
    border-radius: unset;
  }

  .flu_shot_header {
    padding: 50% 10px 10px 10px;
    border-radius: unset;
    background-color: unset;
    width: 100%;
  }

  .flu_shot_header h1 {
    font-size: 24px;
    font-weight: 600;
  }

  .flu_shot_header p {
    font-size: 18px;
  }
}
