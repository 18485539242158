.pharmacy_manager {
    padding: 1px;
    background-color: rgba(0,0,0,0.02455555);
}

.pharmacy_manager h4 {
    background-color: #999;
    border-bottom: 1px solid #eee;
    margin: auto;
}

.pharmacy_manager_cont {
    padding: 10px;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.pharmacy_manager_cont a {
    color: green;
}

.staff_member {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.staff_member p {
    font-size: 17px;
    color: #333;
}

.staff_member span {
    font-size: 20px;
    color: #555;
    font-weight: 600;
}

.staff_member a {
    font-size: 15px;
    padding: 10px;
}

.staff_social {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
}

.staff_social a {
    background-color: #1b95e0;
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    padding: 5px 20px;
    border-radius: 3px;
    margin: 5px;
}

.staff_social a#twitter {
    background-color: #1b95e0;
}

.staff_social svg {
    width: 16px;
    height: 16px;
    fill: #fff;
    margin-right: 5px;
}

.staff_social a:hover {
    text-decoration: none;
    color: #fff;
    opacity: 0.8;
}

.staff_profile {
    padding: 10px;
    display: flex;
    border: 1px solid #ddd;
    margin-bottom: 20px;
}

.staff_profile img {
    width: 80px;
    height: 80px;
    border: 1px solid #ddd;
    margin-right: 10px;
}

.staff_profile_cont {
    padding: 10px;
}

.staff_profile_cont h2 {
    font-size: 16px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 14px;
}

.staff_profile_cont span {
    font-size: 15px;
    color: #888;
}

.staff_profile_cont p {
    font-size: 14px;
    color: #444;
}