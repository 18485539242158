.q_header {
    text-align: center;
    padding: 30px;
}

.q_form {
    padding: 10px;
    margin: 30px 0;
}

.q_form input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 8px;
}

.q_form input[type=radio]{
    width: 4%;
    transform: scale(1.3);
}

.q_no {
    margin-left: 20px;
}

.q_form label {
    display: inline-block;
    margin-top: 20px;
    padding: 7px 0;
    font-size: 15px;
    color: #222;
    font-weight: 600;
}

.q_form sup {
    color: #c4d600;
    font-size: 10px;
}

.q_form select {
    width: 37%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.q_form input[type=checkbox]{
    width: 2%;
}

p.q_owner {
    font-size: 15px;
    color: #222;
    font-weight: 600;
    margin-top: 20px;
}

.q_form input[type=submit]{
    width: 200px;
    padding: 8px 10px;
    background-color: #c4d600;
    color: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    margin-right: 20px;
}

.q_form input[type=reset]{
    width: 200px;
    padding: 8px 10px;
    background-color: #222;
    color: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
}