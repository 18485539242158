.mainHeader {
  background-image: url("../../assets/images/webp/become-a-supplier.webp");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center -100px;
  background-attachment: fixed;
}

@media (max-width: 1300px) {
  .mainHeader {
    background-attachment: scroll;
  }
}

@media (max-width: 992px) {
  .mainHeader {
    background-size: 100%;
  }
}

@media (max-width: 600px) {
  .mainHeader {
    background-size: 120%;
    background-position: center;
    background-attachment: scroll;
  }
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  text-align: center;
}

/*# sourceMappingURL=BecomeASupplier.module.css.map */
