/**
*
*   Company Info Component CSS (c) 2021
*
*/
.mainHeader {
  background-image: url("../../assets/images/Home page 04_compressed.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center -100px;
  background-attachment: fixed;
}

@media (max-width: 1300px) {
  .mainHeader {
    background-attachment: scroll;
  }
}

@media (max-width: 992px) {
  .mainHeader {
    background-size: 100%;
  }
}

@media (max-width: 600px) {
  .mainHeader {
    background-size: 120%;
    background-position: center;
    background-attachment: scroll;
  }
}


.container {
  background-color: #f8f8f8;
  font-size: 18px;
}

.story,
.team,
.partners,
.contact,
.mission {
  margin: 15px 0;
  padding: 0 10px;
}

.story,
.team,
.partners,
.contact {
  padding-top: 15px;
  padding-bottom: 15px;
}

.about {
  margin: 30px 0;
  background-image: url("../../assets/images/about.jpg");
  padding: 320px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: auto 100%;
  position: relative;
}

.overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  background-position: center center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.mission {
  text-align: center;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 3px;
}

.image {
  width: 100%;
  object-fit: fill;
  -o-object-fit: fill;
}

.btn {
  padding: 11px;
  font-size: 16px;
  border: none;
  background-color: #c4d600;
  color: #fff;
  border-radius: 5px;
}

.btn:hover {
  background-color: rgba(196, 214, 0, 0.7);
  color: #fff;
}

@media screen and (max-width: 280px) {
  .mission {
    width: 220px;
  }
}
