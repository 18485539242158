.left_content{
    position: absolute;
    right: 0;
    max-width: 350px;
    width: 100%;
    background-color: #f8f8f8;
    height: 100vh;
    color: #5c7c6c;
}

.left_content a{
    color: #5c7c6c !important;
}

.left_content .list_group_item{
    border-radius: 0 !important;
    background-color: #f8f8f8 !important;
    color: #5c7c6c;
    border: none !important;
    text-decoration: none;
    cursor: pointer;
    padding: 1rem;
    transition: 100ms ease-in;
}

.left_content .list_group_item:hover{
    border-radius: 0 !important;
    background-color: #eee !important;
    text-decoration: underline;
    font-weight: bold;
    font-family: "OpenSans-Bold", sans-serif !important;
}

.sub_item {
    padding-left: 2rem !important;
}


/*** hyperlink styles ***/
.left_content .list_group_item a {
    text-decoration: none;
}

.left_content .list_group_item:hover a {
    background-color: #eee !important;
}
/*** end hyperlink styles ***/

.icon {
    color: #b5c500;
    font-size: 18px;
    margin-right: 20px;
    float: left;
    width: 1em !important;
}
