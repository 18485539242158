.s_form_flex {
    background-color: #f4f4f0;
    padding: 20px 0;
}

.s_form_cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.s_form_cont form {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 30px;
    width: 100%;
    max-width: 1100px;
}