.shipping{
    margin-top: 30px;
}

.shipping a{
    color: green;
}

.questionaire{
    padding: 0;
    margin-top: 10px;
}

.list_group_item{
    padding-bottom: 0 !important;
    border-radius: 0px !important;
    border-left: none !important;
    border-right: none !important;
    background-color: unset !important;
    cursor: pointer;
}

.question p{
    color: green;
}

.answer{
    display: none;
}