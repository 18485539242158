.order_header {
    padding: 20px 0;
    text-align: center;
}

.order_header h1 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
}

p.order_number {
    font-size: 17px;
    color: #111;
}

.order_flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.order_cont {
    text-align: center;
    border: 1px solid #333;
    padding: 10px;
    width: 270px;
    margin: 100px 0;
}

.order_cont h5 {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    margin: 20px;
}

.order_cont p {
    font-size: 16px;
    color: #222;
}

@media(max-width: 870px){
    .order_cont {
        width: 260px;
        margin: 10px 0;
    }

    .order_flex {
        flex-direction: column;
        align-items: center;
    }
}