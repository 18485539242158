.notificationsContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem;

}

.notification {
  background-color: #f8f8f8;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-radius: 4px;
  margin: 0.5rem;
  display: flex;

  align-items: center;
}

.notificationText {
  flex: 1;
}

.cancelIcon {
  margin-left: 1rem;
  color: #777;
  cursor: pointer;
  transition: 100ms ease-in;
}

.cancelIcon:hover {
  color: #ADD036;
}

.cartNotification {
  background-color: #f8f8f8;
  display: flex;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  min-width: 320px;
  border-radius: 4px;
  overflow: hidden;
}

.cartNotificationImage {
  width: 75px;
  height: 75px;
  min-width: 75px;
  min-height: 75px;
  background-color: white;
  object-fit: contain;
  padding: 0.5rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.cartNotificationDetails {
  padding: 1rem;
  width: 100%;

  p {
    margin: 0;
  }

  span {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.cartNotificationExpand {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: #f8f8f8;
  overflow: hidden;
}

.expandItemsContainer {
  max-height: 30vh;
  overflow-y: auto;
}

.expandItem {
  display: flex;
  background-color: white;
  overflow: hidden;
  position: relative;
}

.linkContainer {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  gap: 10px;

  a {
    text-align: center;
    width: 100%;
    background-color: #c4d600;
    padding: 0.5rem 1rem;
    color: white;
    font-weight: 600;
    border-radius: 4px;
  }
}

.expandHeader {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
  }
}

.expandImage {
  min-width: 70px;
  min-height: 70px;
  height: 70px;
  width: 70px;
  background-color: white;
  object-fit: contain;
  padding: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.expandItemDetails {
  padding: 0.5rem;
  font-size: 13px;
  width: 100%;

  p {
    margin-bottom: 5px;
    font-size: 14px;
  }

  h6 {
    font-size: 14px;
    margin: 0;
  }

  span {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.deletedContainer {
  background-color: #c4d600;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    color: white;
    font-weight: 600;
  }
}