.contactUs {
    background-color: #f8f8f8;
    font-size: 17px;
}

.link {
    color: #000;
}

.link:hover {
    color: #000;
}

.icons {
    color: #c4d600;
}

.openingHours h5 {
    font-size: 1.1rem;
}

.openingHours p {
    font-size: 1rem;
    margin-bottom: 5px;
}

.openingHours > p:last-child {
    margin-bottom: 20px;
}

.disclaimer {
    margin-block: 20px;
}

@media screen and (max-width: 400px) {
    .info {
        font-size: 17px;
    }
}

@media screen and (max-width: 360px) {
    .info {
        font-size: 16px;
    }
}

@media screen and (max-width: 320px) {
    .info {
        font-size: 14px;
    }
}

