.aside_pharmacy_navbar {
    padding: 20px;
    border-radius: 10px;
}

.aside_pharmacy_navbar ul {
    margin: 0;
    padding: 0;
}

.aside_pharmacy_navbar li {
    list-style-type: none;
    margin-bottom: 10px;
}

.aside_pharmacy_navbar a {
    text-decoration: none;
    font-size: 15px;
    color: #1890ff;
}

@media (max-width: 767px){
    .aside_pharmacy_navbar {
        display: none;
    }
}