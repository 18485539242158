.returns{
    padding: 20px;
    max-width: 800px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.returns img{
    width: 100%;
}

.near_footer{
    min-height: 40px;
    background-color: rgb(214, 214, 214);
    width: 100%;
    text-align: center;
}

.near_footer img{
    width: 60px;
    margin: 10px;
}