.sf_nav {
    padding: 24px 0;
}

.sf_nav a {
    text-decoration: none;
    font-size: 16px;
}

.sf_nav span {
    display: inline-block;
    margin: 0 10px;
}

.sf_nav h1 {
    font-size: 24px;
    font-weight: 700;
    color: #222;
}

.sf_header {
    padding: 30px 0;
}

.sf_header h2 {
    font-size: 25px;
    color: #333;
    font-weight: 700;
}

.sf_cont {
    padding: 40px 0;
}