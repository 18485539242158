.left_content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 130px;
  max-width: 350px;
  width: 100%;
  background-color: #f8f8f8;
  color: #5c7c6c;
  overflow-y: scroll;
}

/* .left_content::-webkit-scrollbar {
  display: none;
} */
.left_content a {
  color: #5c7c6c !important;
}

.left_content .list_group_item {
  border-radius: 0 !important;
  background-color: #f8f8f8;
  color: #5c7c6c;
  border: none !important;
  cursor: pointer;
  padding: 1rem;
  transition: 100ms ease-in;
}

.sub_item {
  padding-left: 2rem !important;
}

.left_content a {
  text-decoration: none !important;
}

.left_content .list_group_item:hover {
  border-radius: 0 !important;
  background-color: #eee;
  text-decoration: underline;
  font-weight: bold;
  font-family: "OpenSans-Bold", sans-serif !important;
}

.back_btn {
  font-weight: 500;
  font-family: "Open Sans Medium", sans-serif;
}

@media screen and (max-width: 990px) {
  .left_content {
    bottom: 115px;
  }
}
@media screen and (max-width: 600px) {
  .left_content {
    bottom: 50px;
  }
}

