.form_container{
    width: 100% !important;
    display: flex;
    justify-content: center;
}

.search_button{
    background-color: #635b5b !important;
    border-color: #635b5b !important;
    border-radius: 0 !important;
    height: 40px !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    width: 50px;
    margin-top: 1px;
}

.search_wrapper{
    max-width: 810px;
    width: 100%;
    font-family: "Open Sans", sans-serif;
}

@media(max-width: 1300px){
    .search_wrapper{
        max-width: 700px !important;
    }
}
