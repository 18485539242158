.frontend_cart{
    background-color: #fafafa !important;
    margin-top: 0;
}
.cart_title, .cart_title_icon{
    font-size: 30px !important;
    color: #635b5b !important;
}
.total_cart_money, .total_qnty, .total_unit_price{
    color: #000 !important;
}

.cart_paper{
    padding: 20px;
    margin-top: 5%;
    text-align: center;
    min-height: 150px;
}

.empty_cart_typography{
    color: rgb(102, 102, 102);
}

.cart_item_container{
    display: flex;
    flex-direction: row;
    border: 1px solid #ccc;
    width: 100%;
    padding: 20px;
    min-height: 30px;
    border-radius: 5px;
    margin-bottom: 10px !important;
    position: relative;
    overflow: hidden;
}

.loadingIndicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(248, 248, 248, 1);

    z-index: 1000;
}


.cartItemContainer {
    display: flex;
    border: 1px solid #e2e2e2;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    align-items: stretch;
    box-sizing: border-box;
}

.cartItemDetails {
    display: flex;
}

.cartImageContainer {
    width: 120px;
    height: 120px;
    min-width: 90px;
    min-height: 90px;
    padding: 0.5rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cartImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.cartInfoContainer {
    padding-top: 5px;
    padding-left: 15px;
}

.cartActionsContainer {
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.saveRemove {
    display: flex;
    align-items: center;
    align-self: flex-end;
    justify-self: flex-end;
}

.saveRemove span {
    margin: 0 5px;
    cursor: pointer;
    transition: 100ms ease-in;

    white-space: nowrap;
    word-break: keep-all;
}

.saveRemove span:hover {
    color: #c4d600;
}

.cart_increment_decrement{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 7px
}

.cart_increment_decrement .cart_operators{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 5px;
    background-color: #b5c500;
    color: white;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: 50ms ease-in;
}

.cart_increment_decrement .cart_operators:active {
    transform: scale(0.75);
}

.cart_increment_decrement .cart_quantity{
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border: 1px solid gray;
}

.cartItemTotal {
    text-align: right;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-self: center;
}

.cartItemTotal h4 {
    margin: 0;
}

.order_summary_container{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.order_summary_left{
    display: flex;
    flex-direction: row;
    flex: 1;
}

.order_summary_right{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: right;
}

.frontend_cart .btn_primary{
    width: 100% !important;
    border-radius: 50px !important;
}

.empty_class_container{
    margin-top: 5%;
}

.empty_shopping_cart_container{
    text-align: center;
}

.empty_class_container p{
    font-size: 50px;
}

.empty_class_container .btn-primary{
    max-width: 250px;
}

.category_wording_container p{
    font-size: 16px !important;
}

@media (max-width: 767px){
    .cart_title, .cart_title_icon{
        font-size: 20px !important;
    }

    .cart_increment_decrement{
        display: flex;
        flex-direction: row;
    }
}

@media screen and (max-width: 600px) {

    .cartItemContainer {
        flex-wrap: wrap;
    }

    .cartActionsContainer {
        padding: 0.5rem 1rem 1rem 1rem;
    }

    .cartImageContainer {
        min-width: 120px;
        min-height: 120px;
        height: 120px;
        width: 120px;
    }

    .cartInfoContainer h6 {
        white-space: initial;
        text-wrap: normal;
    }

}

@media screen and (max-width: 320px) {
    .cartImageContainer {
        min-width: 75px;
        min-height: 75px;
        height: 75px;
        width: 75px;
    }

    .cartInfoContainer h6 {
        white-space: break-spaces;
    }
}
