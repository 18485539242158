.n_nav {
    padding: 20px 0;
}

.n_nav a {
    font-size: 15px;
    text-decoration: none;
}

.n_nav h1 {
    font-size: 25px;
    font-weight: 700;
    color: #333;
    padding-top: 5px;
}

.n_header {
    padding: 10px 0;
}

.n_header h2 {
    font-size: 16px;
    line-height: 1.8;
    color: #333;
}

.n_cont p {
    font-size: 16px;
    color: #222;
    line-height: 1.8;
}

p.n_line {
    text-decoration: underline;
}