.addressBook {
  background-color: #f8f8f8;
  font-size: 18px;
  padding: 25px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.form {
  width: 650px;
}

.addressItem {
  display: flex;
  width: 60%;
  margin: 20px 0;
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
}

.actionbuttons {
  border: none;
  margin: 7px;
  font-size: 18px;
  font-weight: 400;
  background-color: transparent;
  border-radius: 3px;
}

.addressInfo {
  margin-right: auto;
}
.addressInfo ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.addressInfo ul li {
  font-size: 16px;
}

.actions {
  display: flex;
  flex-direction: column;
}

.btn {
  background-color: #add036;
  margin: 5px 0;
  padding: 0.5rem 1rem;
  text-align: center;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  transition: 100ms ease-in;
}

.btn:hover {
  color: white;
  background-color: #beda5f;
}

@media screen and (max-width: 974px) {
  .addressItem {
    width: 100%;
    min-width: 200px;
  }
}
@media screen and (max-width: 720px) {
  .form {
    width: 100%;
  }
}
@media screen and (max-width: 436px) {
  .addressInfo {
    margin-bottom: 10px;
  }

  .addressItem {
    flex-direction: column;
  }
}
