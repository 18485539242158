.o_nav {
    padding: 20px 0;
}

.o_nav a {
    font-size: 15px;
    text-decoration: none;
}

.o_nav span {
    display: inline-block;
    margin: 0 5px;
    font: 15px;
    color: #333;
}

.o_nav h1 {
    font-size: 25px;
    font-weight: 700;
    color: #333;
    padding-top: 5px;
}

.o_header {
    background-color: #f1f1f0;
    border-radius: 3px;
    text-align: center;
    padding: 10px 150px;
}

.o_header h2 {
    font-size: 45px;
    font-weight: 700;
    color: #333;
}

.o_trust {
    padding: 40px 0;
    text-align: center;
}

.o_trust h3 {
    font-size: 18px;
    font-weight: 500;
    color: #333;
}

.o_flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px 0;
}

.o_flex_cont {
    padding: 10px;
    width: 267px;
}

.o_flex_cont svg {
    width: 80px;
    height: 120px;
    fill: #c4d600;
}

.o_flex_cont p {
    font-size: 16px;
    color: #222;
}

.o_flex_cont b {
    font-size: 20px;
    color: #222;
}

p.o_contact {
    font-size: 15px;
    text-align: left;
    padding-bottom: 20px;
}

.p_header h4 {
    font-size: 24px;
    font-weight: 700;
    color: #333;
}

.p_header p {
    color: #000;
}

.p_security p, .p_security li, .p_security b{
    color: #222;
    font-size: 16px;
    line-height: 1.8;
}

.p_security h5 {
    font-size: 24px;
    color: #222;
    font-weight: 600;
}

@media(max-width: 1200px){
    .o_header {
        padding: 10px 52px;
    }
}

@media(max-width: 898px) {
    .o_flex{
        flex-direction: column;
        align-items: center;
    }

    .o_header {
        padding: 10px 20px;
    }
}

@media(max-width: 520px){
    .o_nav h1 {
        font-size: 18px;
    }

    .o_header {
        padding: 10px 20px;
    }

    .o_header h2 {
        font-size: 27px;
    }
}

@media(max-width: 350px){
    .o_nav h1 {
        font-size: 16px;
    }
    .o_flex_cont {
        width: 220px;
    }

    .o_header {
        padding: 10px 10px;
    }

    .o_header h2 {
        font-size: 22px;
    }
}