body {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fafafa;
}

input {
  background-color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: black !important;
}

.container {
  max-width: 1300px !important;
  width: 100% !important;
  padding-left: 30px;
  padding-right: 30px;
}

.btn-primary {
  background-color: #add036 !important;
  border-color: #add036 !important;
  font-size: large !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.089) !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 25% !important;
  height: 25% !important;
}

.react-pdf__Page__textContent {
  display: none;
}

@media screen and (max-width: 600px) {
  .container {
    padding: 0 10px;
  }
}
