.myHealthInsurance {
  background-color: #f8f8f8;
  font-size: 18px;
  padding: 25px;
  height: 100%;
}

.button {
  border-radius: 100px;
  padding: 5px 30px;
  background-color: #add036;
  color: white;
}
.button :hover,
.button :active {
  color: white;
}

.stepCounter {
  background-color: #add036;
  padding: 0.5rem 1rem;
  color: white;
  display: flex;
  border-radius: 4px;
  margin: 10px 0;
}
.stepCounter div {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background-color: #8dab28;
  border-radius: 50%;
  text-align: center;
}

.form {
  width: 650px;
}

@media screen and (max-width: 720px) {
  .form {
    width: 100%;
  }

  .button {
    width: 100%;
  }
}
