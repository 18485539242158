.mainHeader {
    background-image: url("../../assets/images/webp/specialty-pharmacy.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

@media (max-width: 1300px) {
    .mainHeader {
        background-attachment: scroll;
    }
}

@media (max-width: 992px) {
    .mainHeader {
        background-size: 100%;
    }
}

@media (max-width: 600px) {
    .mainHeader {
        background-size: 190%;
        background-position: center;
        background-attachment: scroll;
    }
}