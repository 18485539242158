.title {
  color: black;
  font-size: 35px;
  font-weight: 500;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.icons a {
  font-size: 30px;
  color: black;
}
.icons a :hover {
  transition: 100ms ease-in;
  color: gray;
}

