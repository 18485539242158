@tailwind base;
@tailwind components;
@tailwind utilities;

/*OpenSans*/
@font-face {
  font-family: "OpenSans-Bold";
  src: url("../assets/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf")
    format("truetype");
}
@font-face {
  font-family: "OpenSans-Light";
  src: url("../assets/fonts/Open_Sans/static/OpenSans/OpenSans-Light.ttf")
    format("truetype");
}
@font-face {
  font-family: "OpenSans-Medium";
  src: url("../assets/fonts/Open_Sans/static/OpenSans/OpenSans-Medium.ttf")
    format("truetype");
}
@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "Open Sans SemiBold";
  src: url("../assets/fonts/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf")
    format("truetype");
}
@font-face {
  font-family: "Open Sans BoldItalic";
  src: url("../assets/fonts/Open_Sans/static/OpenSans/OpenSans-BoldItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Open Sans ExtraBold";
  src: url("../assets/fonts/Open_Sans/static/OpenSans/OpenSans-ExtraBold.ttf")
    format("truetype");
}
@font-face {
  font-family: "Open Sans ExtraBoldItalic";
  src: url("../assets/fonts/Open_Sans/static/OpenSans/OpenSans-ExtraBoldItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Open Sans Condensed-Bold";
  src: url("../assets/fonts/Open_Sans/static/OpenSans_Condensed/OpenSans_Condensed-Bold.ttf")
    format("truetype");
}
@font-face {
  font-family: "Open Sans Condensed";
  src: url("../assets/fonts/Open_Sans/static/OpenSans_Condensed/OpenSans_Condensed-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "Open Sans All";
  src: url("../assets/fonts/Open_Sans/OpenSans-VariableFont.ttf")
    format("truetype");
}

@font-face {
  font-family: "Open Sans Italic";
    src: url("../assets/fonts/Open_Sans/static/OpenSans/OpenSans-Italic.ttf")
        format("truetype");
}

@layer base {
  a {
    transition: all;
    transition-duration: 100ms;
    transition-timing-function: ease-in;
  }
  a:hover {
    @apply text-link-green;
  }
  .magnifier-container {
    height: 100% !important;
    z-index: 1000 !important;
  }
  .magnifier-image {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
  }

  .main-container {
    padding-top: 108px !important;
  }


  @media screen and (max-width: 768px) {
   .main-container {
      padding-top: 164px !important;
   }
  }
}

@layer components {
  input[type="number"] {
    input[type='number'] {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      & {
        -moz-appearance: textfield;
      }
    }
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }
}

