
.purchaseHistoryOrderDetail {
  /*background-color: #f8f8f8;*/
  font-size: 18px;
}

.table {

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  -webkit-overflow-scrolling: touch;

  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  padding-bottom: 10px;

}

.table::-webkit-scrollbar {
  display: none;
}


@media screen and (max-width: 400px) {
  .id {
    font-size: 13px;
  }
}

