.prescription_details {
  padding: 15px;
}

.items_container {
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 1px gray;
  padding: 5px;
  margin-top: 10px !important;
}

.details_container {
  width: 100%;
  margin-left: 20px;
}

.prescriptionItemContainer {
  margin-bottom: 10px;
}

.prescriptionItem {
  display: flex;
  border: 1px solid #e2e2e2;
  border-radius: 4px 4px 0 4px;
  background-color: #f8f8f8;
  cursor: pointer;
}

.imageContainer {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  background-color: white;
  object-fit: contain;
}
.imageContainer img {
  width: 100%;
  height: 100%;
}

.prescriptionItemDetails {
  display: flex;
  background-color: #f8f8f8;
  padding: 1rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.prescriptionItemDetails span {
  display: flex;
  justify-content: space-between;
}
.prescriptionItemDetails span h6 {
  margin: 0;
}
.prescriptionItemDetails p {
  margin: 0;
}

.prescriptionItemsDropdown {
  padding: 0;
  margin: 0 0 0 15px;
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
.prescriptionItemsDropdown li {
  list-style: none;
  display: flex;
}
.prescriptionItemsDropdown li:nth-child(even) {
  background-color: #f8f8f8;
}

.dropdownItemImage {
  width: 100px;
  height: 100px;
  min-height: 100px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdownItemImage img {
  width: 100%;
  height: 100%;
}

.dropdownItemDetails {
  padding: 0.5rem;
  width: 100%;
}
.dropdownItemDetails span {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.priceContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.priceContainer p {
  font-size: 18px;
}

.dropdownItemTitle {
  color: green;
  margin: 0;
  font-family: "Open Sans SemiBold";
    font-size: 14px;
  margin-bottom: 7px;
}

.dropdownItemPrice {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}
@media screen and (max-width: 768px) {
  .dropdownItemPrice {
    font-size: 16px !important;
  }
}

.addToCartButton {
  background-color: #c4d600;
  padding: 0.5rem 1rem;
  color: white;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

