.p_policy {
    width: 840px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

.p_policy h1 {
    font-size: 32px;
    color: #222;
    font-weight: 700;
}

.p_policy h2 {
    font-size: 20px;
    color: #222;
    font-weight: 500;
    padding: 10px 0;
    margin-bottom: .5em;
}

.p_policy p {
    font-size: 15px;
    color: #111;
    line-height: 1.8;
    margin-bottom: 1em;
}

@media(max-width: 940px) {
    .p_policy {
        width: 600px;
    }
}

@media(max-width: 700px) {
    .p_policy {
        width: 400px;
    }
}

@media(max-width: 500px) {
    .p_policy {
        width: 280px;
    }
}

@media(max-width: 300px) {
    .p_policy {
        width: 200px;
    }

    .p_policy h1 {
        font-size: 25px;
    }
}