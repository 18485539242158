.links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.links li {
  margin: 0 20px;
  color: #607905;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.links li a {
  color: #607905;
}

.account_nav {
  padding: 20px 0 5px 10px;
}

.header {
  padding: 10px;
}

.title {
  font-size: 28px;
  font-weight: 600;
  margin: auto;
}

.top_menu {
  background-color: white;
  padding-bottom: 1rem;
}

.account_main_content {
  margin-top: 20px;
}

.account_main_container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.menu_btn {
  position: relative;
}
.menu_btn :hover {
  color: #87aa07;
}

.links .menu_btn:first-child {
  margin-left: 0;
}

.menu_btn:hover .dropdown {
  display: block;
}

.dropdown {
  position: absolute;
  top: 20px;
  left: 0;
  background-color: white;
  margin: 0;
  padding: 0.5rem 1rem;
  min-width: 200px;
  width: auto;
  list-style: none;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: none;
}
.dropdown li {
  padding: 0;
  font-weight: normal;
  white-space: nowrap;
  margin: 10px 20px;
}

.account {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 20px;
}

.sidebar {
  display: block;
  width: 300px;
  height: 100%;
  border-radius: 4px;
}

.sidebarProfile {
  background-color: #f3f3f3;
  display: grid;
  grid-template-columns: 100px 1fr;
  padding: 1rem;
  margin-bottom: 5px;
  font-size: 18px;
  word-break: break-word;
  place-content: center;
  cursor: pointer;
}
.sidebarProfile strong {
  word-break: keep-all;
  white-space: nowrap;
}

.sidebarProfile:hover {
  background-color: #f8f8f8;
}

.avatar {
  background-color: #add036;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  font-size: 27px;
  font-weight: bold;
}

.sidebarContent {
  margin: 0 0 5px;
  padding: 0;
  background-color: #f3f3f3;
}

.subMenu {
  margin: 0;
  padding: 0;
}

.sidebarItem {
  list-style: none;
  color: #5c7c6c;
  padding: 1rem 2rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.sidebarItem a {
  text-decoration: none;
  color: #5c7c6c;
  font-size: 16px;
  font-weight: 500;
}

.subMenuItem {
  list-style: none;
  color: #5c7c6c;
  padding: 1rem 2rem 1rem 4.5rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}
.subMenuItem a {
  text-decoration: none;
  color: #5c7c6c;
  font-size: 16px;
  font-weight: 500;
}

.icon {
  color: #add036;
  margin-right: 15px;
  width: 20px;
}

.sidebarItem:hover,
.subMenuItem:hover {
  background-color: #f8f8f8;
  text-decoration: underline;
  font-weight: bold;
  white-space: nowrap;
}
.sidebarItem:hover a,
.subMenuItem:hover a {
  font-weight: bold;
}

.idleContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.prescriptions_title {
  font-weight: bold;
  font-size: 34px;
}

.image_container {
  width: 100%;
  height: 200px;
  margin: 30px 0;
}
.image_container img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.btn {
  background-color: #c4d600;
  color: white;
  font-size: 15px;
  padding: 0.5rem 2rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
  border-radius: 50px;
  border: 1px solid rgba(85, 85, 85, 0.1333333333);
  transition: 100ms ease-in;
}

.btn:hover {
  background-color: #adbd00;
  color: white;
  text-decoration: none;
}

.rewards {
  margin-top: auto;
  background-color: white;
  height: fit-content;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 1px 1px 10px rgba(85, 85, 85, 0.33);
  background-image: url("../../assets/images/shopping_1.png");
  background-size: 100px 100px;
  background-repeat: no-repeat;
  background-position: 95% 90%;
}
.rewards h5 {
  font-size: 25px;
  color: #444;
}
.rewards h4 {
  font-size: 34px;
  font-weight: bold;
}
.rewards img {
  height: 500px;
  object-fit: contain;
  margin: 0 auto;
}

.mobileTopBar {
  background-color: #f3f3f3;
  margin-bottom: 20px;
  padding: 1rem;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
}

@media screen and (max-width: 970px) {
  .idleContent {
    flex-direction: column;
  }

  .prescriptions {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .rewards {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .sidebar {
    margin: 0;
    width: 100%;
  }

  .account {
    display: flex;
    flex-direction: column;
  }
}
