.overlay {
  position: fixed;
  top: 110px;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1002;
}

@media screen and (max-width: 768px) {
  .overlay {
    top: 156px;
  }
}

@media screen and (max-width: 600px) {
  .overlay {
    top: 50px;
  }
}

