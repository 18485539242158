.r_header {
    padding-top: 35px;
}

.r_header h1 {
    font-size: 24px;
    color: #333;
}

.r_cont p {
    font-size: 16px;
    color: #444;
}

.r_table table {
    padding: 10px;
    width: 100%;
    border-collapse: collapse !important;
    margin-top: 20px;
}

.r_table table th, .r_table table td {
    padding: 10px !important;
    font-size: 18px;
    text-align: left;
    border: 1px solid #ddd;
}

.r_table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.r_table tr:hover {
    background-color: #ddd;
}

.r_notice {
    font-size: 17px;
    color: #333;
    margin-top: 60px;
}

.r_notice a {
    color: cornflowerblue;
    text-decoration: underline;
}

.r_form p {
    font-size: 16px;
    color: #333;
}

.r_form span {
    color: #c4d600;
    font-size: 15px;
}

.r_form label {
    display: block;
    font-size: 17px;
    color: #222;
    margin-top: 20px;
}

.r_form input[type=date] {
    width: 260px;
}

.r_form input, textarea{
    width: 100%;
    border: 1px solid #5555;
    padding: 8px 20px;
    border-radius: 3px;
}

.r_form input[type=checkbox] {
    width: 2%;
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5); 
    -o-transform: scale(1.5);
    transform: scale(1.5);
}

.r_form input[type=submit]{
    width: 260px;
    background-color: #c4d600;
    color: #fff;
    border: 1px solid #b9d304;
    margin: 20px 0;
}

.r_auth {
    margin-top: 20px;
}

.r_auth p {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin: 0 !important;
}

.r_auth span {
    font-size: 15px;
    color: #333;
}

p.r_knowingly {
    font-size: 15px;
    color: #333;
    margin-top: 20px;
}

.r_img {
    background-color: #98903c8c;
    margin-top: 170px;
}

.r_img img {
    width: 100%;
    opacity: 0.9;
}

.r_img h4 {
    opacity: 0.7;
    color: #695548;
    font-weight: 500;
    text-align: center;
    padding: 10px 0;
}

@media(max-width: 767px){
    .r_img {
        margin-top: 30px;
    }
}