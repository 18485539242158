.intro_header {
  padding: 60px 0;
  background-color: #f5f5f0;
}

.intro_wrapper {
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.intro {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.intro p {
  font-size: 30px;
  font-weight: 600;
}

.intro h4 {
  font-size: 50px;
  line-height: 0.2;
  color: #8eaf50;
}

.intro_sign_up {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro_sign_up a {
  text-decoration: none;
  font-size: 17px;
}

.intro_sign_up a.join_free {
  display: inline-block;
  background-color: #c4d600;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 50px;
  width: 50%;
}

.intro_sign_up p {
  font-size: 17px;
}

.exp_bg {
  background-image: url("../../assets/images/grandpa.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 50%;
  border-radius: 10px;
}

.exp_cont {
  padding: 60px;
  width: 50%;
}

.exp_cont h4 {
  font-size: 62px;
  font-weight: 800;
  color: #333;
}

.wave {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 60px;
}

.wave p {
  font-size: 20px;
  color: #333;
}

.wave a {
  text-decoration: none;
  font-size: 20px;
}

.connect_bg {
  background-color: #96a781;
}

.connect {
  padding: 60px;
  text-align: center;
}

.connect h4 {
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  margin: auto;
}

.connect p {
  font-size: 50px;
  color: #333;
}

.more_wrapper {
  background-color: #c7e696;
  padding: 60px 0;
}

.more_bg {
  background-image: url("../../assets/images/stay_health.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 50%;
  border-radius: 10px;
}

.more_cont {
  width: 40%;
  padding: 20px;
}

.more_cont h4 {
  font-size: 60px;
  font-weight: 600;
  color: #333;
  margin: auto;
}

.more_cont p {
  font-size: 20px;
  color: #333;
}

.more_flex {
  display: flex;
  padding: 10px 0;
}

.more_flex p {
  font-size: 24px;
}

.more_flex b {
  font-size: 35px;
  padding-right: 10px;
}

.love_wrapper {
  background-color: #c7e696;
  padding: 60px 0;
}

.love_header h4 {
  font-size: 50px;
  font-weight: 600;
  color: #333;
  margin-bottom: 125px;
}

.love_bg {
  background-image: url("../../assets/images/Healthy_Kids.jpg");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: right;
  border-radius: 10px;
}

.love_cont {
  padding: 40px;
  width: 31%;
}

.love_cont h4 {
  font-size: 42px;
  color: #333;
  font-weight: 600;
}

.love_cont p {
  font-size: 24px;
  color: #333;
}

.goals_wrapper {
  background-color: #fff;
  padding: 60px 0;
}

.goals_bg {
  background-image: url("../../assets/images/free_trophy_1.jpg");
  background-repeat: no-repeat;
  background-size: 15%;
  background-position: right;
}

.goals_cont {
  width: 50%;
  padding: 60px;
}

.goals_cont h4 {
  font-size: 60px;
  font-weight: 600;
  color: #8eaf50;
}

.goals_cont p {
  font-size: 20px;
  color: #333;
}

.give_card_wrapper {
  padding: 60px 0;
}

.myhealth_give_card {
  padding: 10% 1%;
}

.myhealth_give_card_cont {
  padding: 10% 0% 5% 0%;
  float: left;
  width: 40%;
}

.myhealth_give_card_cont h4 {
  font-size: 62px;
  color: rgb(142, 175, 80);
  font-weight: 600;
}

.myhealth_give_card_cont p {
  font-size: 24px;
  color: #333;
}

.myhealth_give_card_img {
  padding: 10px;
  float: left;
  width: 50%;
}

.myhealth_give_card_img img {
  width: 62%;
}

.make_wrapper {
  background-color: #c7e696;
  padding: 60px 0;
}

.make_bg {
  background-image: url("../../assets/images/smile.jpg");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: right;
  border-radius: 10px;
}

.make_cont {
  padding: 170px 60px;
  width: 50%;
}

.make_cont h4 {
  font-size: 60px;
  font-weight: 600;
  color: #333;
  margin: auto;
}

.make_cont p {
  font-size: 20px;
  color: #333;
}

.better_wrapper {
  background-color: #bcc7aa;
  padding: 60px;
}

.better {
  text-align: center;
}

.better h4 {
  font-size: 60px;
  color: #333;
}

.join_wrapper {
  background-color: #c7e696;
  padding: 60px 0;
}

.join {
  text-align: center;
  padding: 20px;
}

.join h4 {
  font-size: 32px;
  font-weight: 600;
  margin: auto;
}

.join p {
  font-size: 18px;
  color: #333;
}

.join a {
  text-decoration: none;
  font-size: 18px;
}

a.join_btn {
  display: inline-block;
  background-color: #c4d600;
  color: #fff;
  padding: 10px 20px;
  border-radius: 50px;
  margin: 20px 0;
}

.faq_header {
  padding-top: 40px;
  padding-bottom: 10px;
}

.faq_header h4 {
  font-size: 32px;
  color: #333;
}

.faq_cont {
  padding: 0;
}

.myhealth_terms_and_condition {
  padding: 10px;
  margin: 15px 0;
}

.myhealth_terms_and_condition h4 {
  font-size: 42px;
  font-weight: 600;
  padding: 10px 0;
}

.title {
  font-size: 5em;
  letter-spacing: 2px;
}

.personalizedExperienceText {
  font-size: 5em;
}

.connectingYouText {
  font-size: 5em;
}

.connectingYouSubText {
  font-size: 4em;
}

@media screen and (max-width: 900px) {
  .title {
    font-size: 4.4em;
    letter-spacing: 1px;
  }

  .personalizedExperienceText {
    font-size: 4em;
  }
}

@media screen and (max-width: 700px) {
  .title {
    font-size: 3.4em;
    letter-spacing: 1px;
  }

  .connectingYouText {
    font-size: 2.6em;
  }

  .connectingYouSubText {
    font-size: 2.2em;
  }
}
