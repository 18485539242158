.supplier_bg {
  padding: 10px;
}

.supplier_header {
  padding: 5% 1%;
  text-align: center;
}

.supplier_header h1 {
  font-size: 42px;
  font-weight: 600;
  color: #222;
}

.supplier_header p {
  font-size: 17px;
  color: #444;
}

.supplier_links {
  padding: 10px;
  float: left;
  width: 100%;
}

.supplier_links a {
  text-decoration: none;
  color: #fff;
}

.supplier_links a:hover {
  text-decoration: none;
  color: #fff;
}

.supplier_links_card {
  background-image: url("../../assets/images/products_display.jpg");
  background-position: cover;
  width: 62%;
  background-size: 100%;
  float: left;
  margin: 10px;
  border-radius: 3px;
}

.supplier_link_card_bg,
.supplier_link_card_bg_1 {
  padding-top: 25%;
}

.supplier_link_card_bg,
.supplier_link_card_bg_1 {
  background-color: rgba(0, 0, 0, 0.4);
  height: 330px;
  border-radius: 3px;
}

.supplier_link_card_bg_1,
.supplier_link_card_bg_2 {
  padding-top: 50%;
}

.supplier_links_card:hover .supplier_link_card_bg,
.supplier_links_card_1:hover .supplier_link_card_bg_1,
.supplier_links_card_2:hover .supplier_link_card_bg_1,
.supplier_links_card_3:hover .supplier_link_card_bg_1,
.supplier_links_card_4:hover .supplier_link_card_bg_1 {
  background-color: rgba(0, 0, 0, 0.7);
}

.supplier_links_card_1 {
  background-image: url("../../assets/images/supply.jpg");
  background-position: center;
  background-size: 500px;
}

.supplier_links_card_2 {
  background-image: url("../../assets/images/source.jpg");
  background-position: center;
  background-size: 500px;
}

.supplier_links_card_3 {
  background-image: url("../../assets/images/supplying_1.jpg");
  background-position: center;
  background-size: 500px;
}

.supplier_links_card_4 {
  background-image: url("../../assets/images/linkpharmacy.jpg");
  background-position: center;
  background-size: 510px;
}

.supplier_links_card_1,
.supplier_links_card_2,
.supplier_links_card_3,
.supplier_links_card_4 {
  background-repeat: no-repeat;
  width: 30%;
  float: left;
  margin: 10px;
  border-radius: 3px;
}

.supplier_links_card p,
.supplier_links_card_1 p,
.supplier_links_card_2 p,
.supplier_links_card_3 p,
.supplier_links_card_4 p {
  font-size: 26px;
  text-align: center;
  text-shadow: 1px -1px 2px #333;
}

#sup_know {
  background-color: #f8fae0;
  padding: 10px;
}

.supplier_home {
  padding: 15%;
}

.supplier_home a {
  display: block;
  background-color: rgba(85, 85, 85, 0.986);
  padding: 8px 10px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-size: 17px;
  border: 1px solid #5555;
  border-radius: 5px;
}

.supplier_home a:hover {
  text-decoration: none;
  color: #fff;
}

.sup_know_header {
  padding: 5%;
  text-align: center;
}

.sup_know_header h1 {
  font-size: 42px;
  color: #333;
  font-weight: 600;
}

.sup_know_header p {
  font-size: 17px;
  color: #222;
}

.sup_header_img {
  background-color: rgba(0, 0, 0, 0.4);
  background-image: url("../../assets/images/products_display_1.jpg");
  background-position: cover;
  background-attachment: fixed;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 400px;
}

.sup_know_cont {
  padding: 30px 10px;
}

.sup_know_cont p {
  font-size: 17px;
  color: #444;
}

.sup_know_cont li {
  font-size: 17px;
  font-weight: 600;
  color: #222;
}

.sup_apply_card {
  padding: 10px;
  float: left;
  width: 100%;
  margin: 20px 0px;
}

.sup_apply_card_img {
  padding: 10px;
  float: left;
  width: 35%;
}

.sup_apply_card_img img {
  max-width: 20vmax;
}

.sup_apply_card_cont {
  padding: 10px;
  float: left;
  width: 55%;
}

.sup_apply_card_cont h5 {
  font-size: 17px;
  font-weight: 600;
  color: #222;
}

.sup_apply_card_cont p {
  font-size: 17px;
  color: #333;
}

@media all and (max-width: 1030px) {
  .supplier_home {
    padding: 25% 10%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .supplier_bg {
    padding: 10px;
  }

  .supplier_header {
    padding: 5% 1%;
    text-align: center;
  }

  .supplier_header h1 {
    font-size: 42px;
    font-weight: 600;
    color: #222;
  }

  .supplier_header p {
    font-size: 17px;
    color: #444;
  }

  .supplier_links {
    padding: 20px;
    float: none;
    width: 100%;
  }

  .supplier_links a:hover {
    text-decoration: none;
    color: #fff;
  }

  .supplier_links_card {
    width: 93%;
    background-size: 100%;
    float: none;
    margin: 0 10px 10px 10px 0;
  }

  .supplier_link_card_bg,
  .supplier_link_card_bg_1 {
    padding-top: 90px;
  }

  .supplier_link_card_bg,
  .supplier_link_card_bg_1 {
    background-color: rgba(0, 0, 0, 0.4);
    height: 225px;
  }

  .supplier_link_card_bg_1,
  .supplier_link_card_bg_2 {
    padding-top: 90px;
  }

  .supplier_links_card_1,
  .supplier_links_card_2,
  .supplier_links_card_3,
  .supplier_links_card_4 {
    background-repeat: no-repeat;
    width: 45%;
    float: left;
    margin: 10px;
  }

  .supplier_home {
    padding: 1%;
    margin-top: 50px;
  }

  .supplier_home a {
    display: block;
    background-color: rgba(85, 85, 85, 0.986);
    padding: 8px 10px;
    text-decoration: none;
    text-align: center;
    color: #fff;
    font-size: 17px;
    border: 1px solid #5555;
    border-radius: 5px;
  }

  .sup_header_img {
    background-color: rgba(0, 0, 0, 0.4);
    background-image: url("../../assets/images/products_display_1.jpg");
    background-attachment: scroll;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 380px;
  }

  .sup_apply_card {
    padding: 10px;
    float: left;
    width: 100%;
    margin: 20px 0px;
  }

  .sup_apply_card_img {
    padding: 10px;
    float: left;
    width: 45%;
    overflow: hidden;
  }

  .sup_apply_card_img img {
    max-width: 20vmax;
  }

  .sup_apply_card_cont {
    padding: 10px;
    float: left;
    width: 50%;
  }

  .sup_apply_card_cont h5 {
    font-size: 17px;
    font-weight: 600;
    color: #222;
  }

  .sup_apply_card_cont p {
    font-size: 17px;
    color: #333;
  }
}

@media all and (max-width: 767px) {
  .supplier_links_card_1 {
    background-size: 165%;
  }

  .supplier_links_card_2 {
    background-size: 165%;
  }

  .supplier_links_card_3 {
    background-size: 165%;
  }

  .supplier_links_card_4 {
    background-size: 168%;
  }

  .supplier_home {
    padding: 1% !important;
  }

  .sup_know_header {
    padding: 5%;
  }

  .sup_header_img {
    height: 400px;
  }
}

@media all and (max-width: 680px) {
  .sup_header_img {
    background-size: 160% !important;
    height: 380px !important;
  }
}

@media (min-width: 200px) and (max-width: 768px) {
  .supplier_bg {
    padding: 10px;
  }

  .supplier_header {
    padding: 5% 2%;
  }

  .supplier_header h1 {
    font-size: 32px;
    padding: 10px 0;
  }

  .supplier_header p {
    font-size: 17px;
  }

  .supplier_links {
    padding: 10px;
    float: none;
    width: 100%;
    margin: auto;
  }

  .supplier_links_card {
    float: none;
    width: 100%;
    margin: 0 0 20px 0;
    border-radius: 3px;
  }

  .supplier_link_card_bg,
  .supplier_link_card_bg_1 {
    padding-top: 70px;
  }

  .supplier_link_card_bg,
  .supplier_link_card_bg_1 {
    height: 190px;
  }

  .supplier_link_card_bg_1,
  .supplier_link_card_bg_2 {
    padding-top: 70px;
  }

  .supplier_links_card_1,
  .supplier_links_card_2,
  .supplier_links_card_3,
  .supplier_links_card_4 {
    background-repeat: no-repeat;
    width: 100%;
    float: none;
    margin: 0 0 20px 0;
  }

  .supplier_home {
    display: none;
  }

  .supplier_home a:hover {
    text-decoration: none;
    color: #fff;
  }

  .sup_know_header {
    padding: 5%;
    text-align: center;
  }

  .sup_know_header h1 {
    font-size: 25px;
    color: #333;
    font-weight: 600;
  }

  .sup_know_header p {
    font-size: 17px;
    color: #222;
  }

  .sup_header_img {
    height: 278px !important;

    background-attachment: scroll;
  }

  .sup_know_cont {
    padding: 30px 20px;
  }

  .sup_know_cont p {
    font-size: 17px;
    color: #444;
  }

  .sup_know_cont li {
    font-size: 17px;
    font-weight: 600;
    color: #222;
  }

  .sup_apply_card {
    float: none;
    width: 100%;
    margin: 10px 0px;
  }

  .sup_apply_card_img {
    padding: 10px;
    float: none;
    width: 100%;
    overflow: hidden;
    text-align: center;
  }

  .sup_apply_card_img img {
    max-width: 48vmax;
  }

  .sup_apply_card_cont {
    float: none;
    width: 100%;
  }

  .sup_apply_card_cont h5 {
    font-size: 17px;
    font-weight: 600;
    color: #222;
  }

  .sup_apply_card_cont p {
    font-size: 17px;
    color: #333;
  }
}
