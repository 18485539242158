.mainHeader {
  background-image: url("../../assets/images/Ear Piercing_compressed.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

@media (max-width: 1300px) {
  .mainHeader {
    background-attachment: scroll;
  }
}

@media (max-width: 992px) {
  .mainHeader {
    background-size: 100%;
  }
}

@media (max-width: 600px) {
  .mainHeader {
    background-size: 120%;
    background-position: center;
    background-attachment: scroll;
  }
}

.e_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), transparent),
    url("../../assets/images/Ear Piercing_compressed.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.e_header {
  padding: 20% 0;
  text-align: center;
}

.e_header h1 {
  font-weight: 700;
  color: #fff;
  font-size: 62px;
  margin-bottom: 7px;
}

.e_header p {
  font-size: 20px;
  color: #fff;
  font-weight: 300;
  line-height: 1.5;
}

.e_cont {
  padding: 30px 0;
}

.e_cont_header {
  padding: 15px 0;
}

.e_cont_header h4 {
  margin: auto;
}

.e_cont_header h4 {
  font-size: 32px;
  color: #333;
  font-weight: 500;
}

.e_cont_header h3 {
  font-size: 25px;
  color: #333;
  font-weight: 500;
}

.e_cont p {
  font-size: 17px;
  color: #222;
}

@media (max-width: 540px) {
  .e_header h1 {
    font-size: 30px;
  }

  .e_cont h4 {
    font-size: 22px;
  }

  .e_cont_header h4 {
    font-size: 24px;
  }

  .e_cont_header h3 {
    font-size: 20px;
    color: #444;
  }

  .e_cont img {
    width: 260px;
  }
}
