.mediclinic_header_container {
  margin-right: 0;
  margin-left: 0;
}

.top_contact_nav {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.top_contact_nav p {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  margin-left: 10px;
  padding: 5px;
  border-radius: 1px;
}

.top_contact_nav svg {
  width: 24px;
  height: 24px;
  fill: #c4d600;
}

.linkmed_bg_img {
  background-image: url("../../assets/images/mediclinic_1.jpg");
  background-repeat: no-repeat;
  background-blend-mode: screen;
  background-size: 100%;
  background-position: top;
  background-attachment: fixed;
}
@media screen and (max-width: 768px) {
  .linkmed_bg_img {
    background-size: cover;
    
    background-position: center;
    
    
    
    
    
    background-attachment: initial;
    
  }
}

@keyframes bg {
  0% {
    transform: scale(0);
    visibility: hidden;
  }
  100% {
    transform: scale(1);
    visibility: visible;
  }
}
.wellness_header {
  text-align: center;
  padding: 200px 0px;
  margin-bottom: 100px;
  animation: bg 1s linear;
  animation-delay: 1s;
  visibility: hidden;
  animation-fill-mode: forwards;
}

.wellness_header p {
  font-size: 32px;
  color: #333;
  font-weight: 400;
}

.wellness_btns {
  padding: 10px;
}

.wellness_btns a {
  text-decoration: none;
  color: #fff;
}

.wellness_btns a:hover {
  text-decoration: none;
  color: #333;
}

.wellness_header span {
  display: inline-block;
  background-color: #c4d600;
  color: #fff;
  padding: 10px;
  margin-right: 5px;
  width: 230px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
}

.wellness_header span:hover {
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
}

.wellness_serv_wrapper {
  display: flex;
  padding: 10px;
  margin-top: -130px;
}

.wellness_serv_about {
  background-color: #ecf87f;
  padding: 1rem;
  flex: 1;
}

.wellness_serv_hours {
  background-color: #81b622;
  padding: 10px;
  flex: 1;
}

.wellness_serv_contact {
  background-color: #5e8809;
  padding: 10px;
  flex: 1;
}

.wellness_serv_contact a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.wellness_serv_contact a:hover {
  text-decoration: none;
  color: #333;
}

.wellness_serv_contact svg {
  width: 30px;
  height: 20px;
  fill: #eee;
}

.wellness_serv_about h5,
.wellness_serv_hours h5,
.wellness_serv_contact h5 {
  color: #333;
  font-size: 17px;
  padding: 10px 0;
}

.wellness_serv_contact p {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wellness_serv_about p,
.wellness_serv_hours p,
.wellness_serv_contact p {
  color: #222;
  font-size: 16px;
}

.health_service_header {
  padding: 30px;
  border-bottom: 1px solid #eee;
}

.service_cont {
  display: flex;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.02);
  padding: 10px;
}

.service_flex {
  display: flex;
  padding: 10px;
}

.service_flex svg {
  fill: yellowgreen;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.service_flex p {
  font-size: 17px;
}

.service_cont_1 {
  padding: 10px;
}

.service_cont_2 {
  padding: 10px;
}

.service_cont_1 h5,
.service_cont_2 h5 {
  font-size: 18px;
  color: #333;
}

.service_cont_1 p,
.service_cont_2 p {
  font-size: 17px;
  color: #444;
}

.book_appointment {
  padding: 10px;
}

.book_appointment p {
  font-size: 18px;
  color: #333;
}

.book_appointment form {
  background-color: #fff;
  padding: 10px;
}

.book_appoint_cont {
  padding: 10px;
}

.book_now_wrapper {
  padding: 10px;
}

.book_appointment_flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.book_appointment_wrapper h5 {
  padding: 10px;
  font-size: 20px;
  color: #333;
}

.book_appointment_wrapper form {
  background-color: #fafafa;
  padding: 40px;
  border-radius: 5px;
}

.book_now {
  display: flex;
}

.book_now p {
  font-size: 20px;
  margin: auto;
}

.book_now img {
  width: 60px;
}

.book_now_btn_wrapper {
  padding: 10px;
}

.book_now_btn_wrapper p {
  font-size: 25px;
  font-weight: 500;
  margin: 10px;
}

.lab_test_wrapper {
  padding: 10px;
}

.lab_test_wrapper h4 {
  color: rgb(67, 94, 5);
  font-size: 20px;
  padding: 30px 0;
}

.lab_test_wrapper p {
  font-size: 17px;
  color: #222;
}

.lab_test_wrapper img {
  width: 180px;
}

.wellness_wrapper {
  padding: 40px 0px;
}

.wellness_main {
  background-color: #fff;
  padding: 5px;
}

.med_header {
  background-color: #c1ee2e;
  border: 1px solid rgba(68, 68, 68, 0.1333333333);
  border-radius: 3px;
  padding: 20px 10px;
  margin-bottom: 5px;
}

a#med_account {
  background-color: rgb(9, 133, 182);
  padding: 5px 10px;
  color: #fff;
  border-radius: 50px;
}

.medicare_services {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid rgba(68, 68, 68, 0.1333333333);
  float: left;
  width: 100%;
}

.medicare_services h4 {
  color: #3c4d04;
  font-size: 20px;
  padding: 20px 0px;
  border-bottom: 1px solid rgba(68, 68, 68, 0.0666666667);
}

.medicare_services ul {
  float: left;
  width: 100%;
}

.medicare_services ul li {
  padding: 10px 0px;
  list-style: none;
  font-size: 15px !important;
  font-weight: 600;
  color: #333;
  float: left;
  width: 100%;
}

.medicare_services a {
  color: #698313;
}

.medicare_services span {
  text-decoration: underline;
  font-weight: 100;
  padding: 0px 10px;
}

.wellness_main p {
  font-size: 15px;
}

.wellness_img {
  background-color: rgba(68, 68, 68, 0.0666666667);
  padding: 1px;
}

.wellness_img p {
  font-size: 14px;
  color: #777;
  padding: 10px;
  text-align: center;
}

.open_hrs,
.contact {
  border: 1px solid rgba(68, 68, 68, 0.0666666667);
  padding: 10px;
}

.open_hrs h4,
.contact h4 {
  color: #add036;
  font-size: 20px;
}

.open_hrs p,
.contact a {
  font-size: 15px;
  color: #444;
  margin: 5px auto;
}

.contact {
  margin-top: 20px;
}

.contact a {
  text-decoration: none !important;
  display: inline !important;
}

.contact i {
  display: inline !important;
}

.find_us {
  background-color: #fff;
  border: 1px solid rgba(68, 68, 68, 0.0666666667);
  padding: 10px;
}

.find_us iframe {
  width: 100%;
  height: 450px;
}

@media (min-width: 200px) and (max-width: 768px) {
  .top_contact_nav {
    display: none;
  }
  .linkmed_bg_img {
    background-size: 200%;
    background-position: top;
  }
  .wellness_bg {
    background-size: 200%;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  .wellness_header {
    text-align: center;
    padding: 0px 0px;
    margin-top: 45px;
  }
  .wellness_header h1 {
    font-size: 32px;
    color: #fff;
  }
  .wellness_header p {
    font-size: 17px;
    color: #fff;
  }
  .linkmed_bg_color_2 {
    padding: 0;
  }
  .wellness_header span {
    padding: 8px;
    margin-right: 0px;
    margin-bottom: 15px;
    width: 229px;
    font-size: 18px;
  }
  .wellness_header_cont {
    padding: 20px;
    position: absolute;
    top: 20px !important;
    left: 20px !important;
    text-align: center;
  }
  .wellness_serv_wrapper {
    flex-direction: column;
    margin-top: 10px;
  }
  .wellness_serv_about h5,
.wellness_serv_hours h5,
.wellness_serv_contact h5 {
    color: #333;
    font-size: 18px;
    font-weight: 600;
  }
  .open_hrs,
.contact {
    border: 1px solid rgba(68, 68, 68, 0.0666666667);
    padding: 2px;
  }
  .open_hrs h4,
.contact h4 {
    color: #add036;
    font-size: 15px !important;
    padding: 5px !important;
  }
  .open_hrs p,
.contact a {
    font-size: 13px !important;
    display: inline-block !important;
    color: #222;
    margin: 2px 0px;
  }
  .contact a {
    text-decoration: none !important;
    display: inline !important;
  }
  .medicare_services ul {
    padding: 0;
  }
  .medicare_services a {
    color: #698313;
  }
  .service_cont {
    flex-direction: column;
    border-radius: 2px;
  }
  .book_appointment_wrapper form {
    padding: 0px;
  }
}
@media only screen and (max-width: 352px) and (min-width: 280px) {
  .linkmed_bg_img {
    background-size: 260%;
  }
  .lab_img img {
    width: 200px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .wellness_bg {
    background-size: 100%;
    background-position: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    margin-top: 5%;
  }
  .wellness_header_wrapper {
    background-color: rgba(85, 85, 85, 0.0666666667);
    padding: 0;
  }
  .wellness_header {
    text-align: center;
    padding: 40px 0px;
  }
  .wellness_header h1 {
    font-size: 62px;
  }
  .wellness_header p {
    font-size: 24px;
    color: #fff;
  }
  .wellness_wrapper {
    padding: 40px 0px;
  }
  .wellness_main {
    background-color: #fff;
    padding: 5px;
  }
}

