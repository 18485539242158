.s_navbar {
    padding: 20px 0;
}

.s_navbar a {
    font-size: 17px;
    display: inline-block;
}

.s_navbar span {
    display: inline-block;
    margin: 5px;
}

.s_header h1 {
    font-size: 24px;
    color: #222;
    font-weight: 700;
}

.s_stages h4 {
    font-size: 20px;
    color: #55582f;
}

.s_flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
}

.s_flex p {
    width: 150px;
    font-size: 16px;
    font-weight: 400;
}

.s_interest h4, .s_interest h3 {
    font-size: 24px;
    font-weight: 700;
    color: #222;
}

.s_access {
    padding: 10px;
    border: 1px solid #C54C6D;
    margin: 20px 0;
}

.s_access p {
    color: #C54C6D;
}

.s_access a {
    text-decoration: underline;
}

.s_conctact h3 {
    font-size: 24px;
    font-weight: 700;
    color: #222;
}

p.interested {
    color: #C54C6D;
}

.s_estate {
    border: 1px solid #C54C6D;
    padding: 10px;
    margin: 20px 0;
}

.s_estate a {
    text-decoration: underline;
}

.s_testmony {
    padding: 10px;
    border: 1px solid #333;
}