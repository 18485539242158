.instore_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), transparent),
    url("../../assets/images/job_4.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
}

/* .instore_color {
    height: 550px;
} */

.instore_header h4 {
  font-size: 35px;
  font-weight: 600;
  color: #fff;
  padding-top: 40%;
}

.instore_cont h4 {
  font-size: 30px;
  font-weight: 600;
  color: #444;
  padding: 20px 0;
}

.instore_cont h5 {
  color: #444;
  font-size: 24px;
  font-weight: 500;
}

.instore_cont p {
  color: #333;
  font-size: 16px;
}

.instore_nav {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px 0;
  margin-top: 30px;
}

.instore_nav a {
  text-decoration: none;
  color: #444;
  font-size: 15px;
  display: inline-block;
  margin-right: 10px;
}

.instore_nav span {
  font-size: 12px;
  display: inline-block;
  margin-left: 2px;
}

.instore_nav a:hover {
  text-decoration: none;
  color: #444;
}

@media (max-width: 400px) {
  .instore_header h4 {
    font-size: 24px;
    padding-top: 50%;
  }
}
