.pharmacy_services_bg {
  background-image: url("../../assets/images/simplify_rx.jpg");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: right;
  background-color: #c4d600;
  margin: 25px 0;
  border-radius: 10px;
}

.pharmacy_services_header {
  padding: 10% 0 10% 4%;
}

.pharmacy_services_header h1 {
  color: #fff;
  font-size: 54px;
  width: 316px;
}

.transfer_rx {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid #ddd;
}

.transfer_rx_cont {
  display: flex;
  flex: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.transfer_rx_cont a {
  font-size: 17px;
  font-weight: 600;
  background-color: #c4d600;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  border-radius: 30px;
}

.transfer_rx_cont a:hover {
  text-decoration: none;
}

.transfer_rx_cont p {
  font-size: 17px;
  color: #333;
  padding: 15px 20px 0 0;
}

.rx_refills {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px 0;
}

.rx_refills_cont {
  text-align: center;
  padding: 20px;
  width: 270px;
  height: 270px;
}

.rx_svg_wrapper {
  background-color: #c4d6003a;
  padding-top: 20px;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  margin: auto;
}

.transfer_rx .svg-inline--fa.fa-w-12 {
  width: 2.89em !important;
}

.rx_svg_wrapper svg {
  width: 60px;
  height: 60px;
  fill: #333;
}

.rx_refills_cont a {
  display: inline-block;
  text-decoration: none;
  font-size: 17px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  margin: 10px;
}

.rx_refills_cont p {
  font-size: 17px;
  color: #333;
}

.turning_wrapper {
  background-color: #dbeac6;
  padding: 20px;
}

.turning {
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.turning_cont_1 {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
}

.turning_cont_bg_1 {
  background-color: #798251;
  background-image: url("../../assets/images/happy-senior-couple-outdoors.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: right;
  border-radius: 10px;
  margin-bottom: 20px;
}

.turning_cont {
  background-color: #798251;
  padding: 25px;
  width: 50%;
  border-radius: 10px 0 0 10px;
}

.turning_cont h2 {
  font-size: 39px;
  color: #eee;
}

.turning_cont p {
  font-size: 20px;
  color: #ddd;
}

.turning_cont a {
  text-decoration: none;
  font-size: 17px;
  color: #fff;
  font-weight: 600;
}

.same_day_cont_bg {
  background-color: #c4d600;
  background-image: url("../../assets/images/delivery.jpg");
  background-repeat: no-repeat;
  background-size: 74%;
  background-position: right;
  border-radius: 10px;
}

.same_day_cont {
  background-color: #c4d600;
  padding: 20px;
  width: 50%;
  border-radius: 10px 0 0 10px;
}

.same_day_cont h2 {
  font-size: 40px;
  color: #fff;
}

.same_day_cont p {
  font-size: 20px;
  color: #fff;
}

.same_day_cont a {
  text-decoration: none;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.virtual_care_bg {
  background-color: #f2f2f2;
  background-image: url("../../assets/images/care_giver.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  border-radius: 10px;
  flex: 1;
}

.virtual_care_cont {
  padding: 58% 8% 12% 8%;
}

.virtual_care_bg h2 {
  font-size: 45px;
  color: #333;
}

.virtual_care_cont p {
  font-size: 27px;
  color: #333;
}

.virtual_care_cont a {
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
}

/* Mobile view  */

@media (max-width: 1318px) {
  .turning {
    padding: 10px;
    display: flex;
    justify-content: space-around;
  }

  .turning_cont_1 {
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }

  .turning_cont_bg_1 {
    background-size: 100%;
    background-position: right;
  }

  .turning_cont {
    padding: 25px;
    width: 50%;
    border-radius: 10px 0 0 10px;
  }

  .turning_cont h2 {
    font-size: 20px;
  }

  .turning_cont p {
    font-size: 16px;
  }

  .turning_cont a {
    text-decoration: none;
    font-size: 17px;
    color: #fff;
    font-weight: 600;
  }

  .same_day_cont_bg {
    background-size: 65%;
    background-position: right;
    border-radius: 10px;
  }

  .same_day_cont {
    padding: 4%;
    width: 50%;
    border-radius: 10px 0 0 10px;
  }

  .same_day_cont h2 {
    font-size: 20px;
    color: #fff;
  }

  .same_day_cont p {
    font-size: 16px;
    color: #fff;
  }

  .same_day_cont a {
    text-decoration: none;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
  }

  .virtual_care_bg {
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    border-radius: 10px;
  }

  .virtual_care_cont {
    padding: 68% 8% 12% 8%;
  }

  .virtual_care_bg h2 {
    font-size: 20px;
    color: #333;
  }

  .virtual_care_cont p {
    font-size: 16px;
    color: #333;
  }

  .virtual_care_cont a {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
  }
}

@media (max-width: 1110px) {
  .pharmacy_services_header {
    padding: 5% 0 5% 5%;
  }
}

@media (max-width: 992px) {
  .pharmacy_services_bg {
    background-size: 50%;
    background-position: right;
    margin: 20px 0;
  }

  .pharmacy_services_header h1 {
    font-size: 30px;
  }

  .pharmacy_services_header {
    padding: 5%;
    width: 100%;
  }

  .transfer_rx_cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: initial;
    padding: 10px;
  }

  .turning {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .turning_cont_1 {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;
  }

  .turning_cont_bg_1 {
    background-size: 100%;
    background-position: top;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .turning_cont {
    background-color: #5555;
    padding: 70% 8% 12% 8%;
    width: 100%;
    border-radius: 10px;
  }

  .turning_cont h2 {
    font-size: 20px;
  }

  .turning_cont p {
    font-size: 16px;
    color: #ddd;
  }

  .turning_cont a {
    text-decoration: none;
    font-size: 17px;
    color: #fff;
    font-weight: 600;
  }

  .same_day_cont_bg {
    background-size: 100%;
    background-position: top;
  }

  .same_day_cont {
    background-color: #5555;
    padding: 70% 8% 12% 8%;
    width: 100%;
    border-radius: 10px;
  }

  .same_day_cont h2 {
    font-size: 20px;
    color: #fff;
  }

  .same_day_cont p {
    font-size: 16px;
    color: #fff;
  }

  .same_day_cont a {
    text-decoration: none;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
  }

  .virtual_care_bg {
    background-size: 100%;
    border-radius: 10px;
    margin: 20px 25px 20px 10px;
  }

  .virtual_care_cont {
    padding: 70% 8% 12% 8%;
  }

  .virtual_care_bg h2 {
    font-size: 20px;
  }

  .virtual_care_cont p {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .pharmacy_services_bg {
    background-size: 100%;
    background-position: top;
    margin: 20px 0;
    border: none;
    border-radius: unset;
  }

  .pharmacy_services_header {
    padding: 90% 10% 10% 10%;
    border-radius: unset;
  }

  .pharmacy_services_header h1 {
    font-size: 24px;
    width: 100%;
  }

  .transfer_rx {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 300px) {
  .rx_refills_cont {
    padding: 10px;
    width: 100%;
    height: 320px;
  }
}
