.contactPreferences {
  background-color: #f8f8f8;
  height: 100%;
  padding: 25px;
}

.section {
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
}

.section div:last-child {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.section div:last-child span label {
  margin-left: 10px;
}

.section h4 {
  font-size: 16px;
  font-weight: 700;
}
