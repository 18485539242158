.mainHeader {
  background-image: url("../../assets/images/stay_health.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center -100px;
  background-attachment: fixed;
}

@media (max-width: 1300px) {
  .mainHeader {
    background-attachment: scroll;
  }
}

@media (max-width: 992px) {
  .mainHeader {
    background-size: 100%;
  }
}

@media (max-width: 600px) {
  .mainHeader {
    background-size: 120%;
    background-position: center;
    background-attachment: scroll;
  }
}


.hero {
  background-color: #c4d600;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../assets/images/about.jpg");
  background-repeat: no-repeat;
  background-position: center 0px;
  background-size: 100%;
  position: relative;
  height: 50vh;
  display: flex;
  align-items: center;
}
.hero h1 {
  color: white;
  font-weight: bold;
}

.content section {
  margin-bottom: 40px;
}

.community {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.community div:nth-child(2) {
  width: 50%;
  height: 250px;
}
.community div:nth-child(2) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.integrity {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 30px;
}
.integrity div:nth-child(2) {
  width: 50%;
  height: 250px;
}
.integrity div:nth-child(2) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*# sourceMappingURL=CompanyInfo.module.css.map */
