.fp_wrapper {
    background-color: #f4f4f0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #5555;
}

.fp_cont {
    text-align: center;
    background-color: #ffff;
    margin: 5% 0;
    padding: 20px;
}

.fp_cont svg {
    width: 20%;
    height: 10%;
    fill: #c4d600;
    margin-bottom: 40px;
}

.fp_cont p {
    font-size: 17px;
    color: #444;
}

.fp_cont a {
    background-color: #c4d600;
    border-radius: 2px;
    font-size: 18px;
    color: #fff;
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    margin-top: 40px;
    width: 50%;
}

@media(max-width: 540px){
    .fp_cont svg {
        width: 80px; 
        margin-bottom: 20px;
    }

    .fp_cont a {
        margin-top: 20px;
    }
}