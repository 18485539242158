.dist_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), transparent),
    url("../../assets/images/dist.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.dist_header h4 {
  font-size: 35px;
  font-weight: 600;
  color: #fff;
  padding-top: 40%;
}

.dist_cont h4 {
  font-size: 30px;
  font-weight: 600;
  color: #444;
  padding: 20px 0;
}

.dist_cont h5 {
  color: #444;
  font-size: 24px;
  font-weight: 500;
}

.dist_cont p {
  color: #333;
  font-size: 16px;
}

.dist_nav {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px 0;
  margin-top: 30px;
}

.dist_nav a {
  text-decoration: none;
  color: #444;
  font-size: 15px;
  display: inline-block;
  margin-right: 10px;
}

.dist_nav span {
  font-size: 12px;
  display: inline-block;
  margin-left: 2px;
}

.dist_nav a:hover {
  text-decoration: none;
  color: #444;
}

@media (max-width: 400px) {
  .dist_header h4 {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    padding-top: 40%;
  }
}
