.job_alert_form {
    padding: 10px;
    border-top: 1px solid #5555;
    margin-top: 20px;
}

.job_alert_form_header {
    text-align: center;
    padding-bottom: 20px;
}

.job_alert_form_header h4 {
    font-size: 30px;
    font-weight: 600;
    color: #444;
    padding: 20px 0;
}

.job_alert_form_header p {
    font-size: 17px;
    color: #444;
}

.form_cont {
    padding: 10px;
}

.form_cont form {
    display: flex;
    flex-wrap: wrap;
    align-items: self-end;
    justify-content: space-between;
}

.job_form_flex {
    display: flex;
    flex-direction: column;
}

.job_form_flex label {
    font-size: 17px;
    color: #111;
}

.job_form_flex input[type=text], .job_form_flex select{
    width: 300px;
    border: 1px solid #5555;
    border-radius: 2px;
    padding: 10px;
}

.form_cont input[type=submit]{
    background-color: #c4d600;
    width: 100%;
    padding: 9px 20px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    border: 1px solid #c4d600;
    border-radius: 2px;
    cursor: pointer;
}

.form_cont input[type=submit]:hover {
    opacity: 0.7;
}