.pack_meds_header {
  background-image: url("../../assets/images/smile_meds.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.pack_meds_header_flex {
  background-color: #10101061;
  display: flex;
  flex-direction: column;
}

.pack_header_cont {
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin: 10% 0;
}

.pack_meds_logo img {
  width: 400px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.pack_meds_title {
  padding: 10px;
}

.order_meds_bg {
  background-image: url("../../assets/images/order_pack_meds.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 50%;
  margin: 60px 0;
}

.order_meds_cont {
  padding: 100px;
  width: 50%;
}

.order_meds_cont h2,
.pre_packed_meds_cont h2,
.collect_packed_meds_cont h2 {
  font-size: 34px;
  color: #333;
}

.order_meds_cont p,
.pre_packed_meds_cont p,
.collect_packed_meds_cont p {
  font-size: 17px;
  color: #444;
}

.pre_packed_meds_bg {
  background-image: url("../../assets/images/pack_meds_package.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 50%;
  margin-bottom: 60px;
}

.pre_packed_meds_cont {
  width: 55%;
  padding: 100px;
}

.collect_packed_meds_bg {
  background-image: url("../../assets/images/collect_packed_meds.jpg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 50%;
  margin-bottom: 60px;
}

.collect_packed_meds_cont {
  width: 40%;
  padding: 74px;
}

.summary_of_how_it_works {
  padding: 10px;
}

.summary_of_how_it_works h4 {
  font-size: 34px;
  color: #444;
  font-weight: 600;
}

.summary_cont ol {
  padding: 25px;
}

.subscribe_for_pack_meds {
  padding: 10px;
}

.subscribe_for_pack_meds h4 {
  color: #444;
  font-size: 34px;
  font-weight: 600;
  padding-bottom: 10px;
}

.subscribe_cont {
  background-color: #f1f1f0;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.02);
  border-radius: 2px;
}

.subscribe_for_pack_meds small {
  font-size: 16px;
  color: #444;
}

.pack_meds_form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
}

.pack_meds_form h4 {
  font-size: 34px;
  padding: 10px 0;
}

.pack_meds_form form {
  background-color: #fafafa;
  border-radius: 5px;
  padding: 40px;
  width: 50%;
}

.pack_meds_form em {
  font-size: 13px;
  float: right;
  color: #a13e3e;
}

/* Mobile View */
@media (max-width: 1300px) {
  .pack_meds_header {
    background-attachment: scroll;
  }

  .order_meds_cont {
    padding: 20px;
  }

  .pre_packed_meds_cont {
    width: 50%;
    padding: 20px;
  }

  .collect_packed_meds_cont {
    padding: 20px;
  }
}

@media (max-width: 992px) {
  .pack_meds_header {
    background-size: 100%;
  }

  .pack_meds_header_flex {
    display: flex;
    flex-direction: column;
  }

  .pack_header_cont {
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin: 10% 0;
  }

  .pack_meds_logo {
    padding: 10px;
    flex: 0.5;
    margin-left: 20px;
  }

  .pack_meds_logo img {
    width: 140px;
    margin-top: 10px;
  }

  .pack_meds_title {
    margin: auto;
  }

  .pack_meds_title h1 {
    font-size: 32px;
    margin: auto;
  }

  .order_meds_bg {
    margin: 60px 0;
  }

  .order_meds_cont {
    padding: 20px;
  }

  .order_meds_cont h2 {
    font-size: 24px;
  }

  .pre_packed_meds_bg {
    background-size: 50%;
    margin-bottom: 60px;
  }

  .pre_packed_meds_cont {
    width: 50%;
    padding: 20px;
  }

  .pre_packed_meds_cont h2 {
    font-size: 32px;
  }

  .pre_packed_meds_cont p {
    font-size: 17px;
  }

  .collect_packed_meds_cont {
    width: 50%;
    padding: 20px;
  }
}

@media (max-width: 600px) {
  .pack_meds_header {
    background-size: 190%;
    background-position: center;
    background-attachment: scroll;
  }

  .pack_header_cont {
    flex-direction: column;
    margin: 10% 0;
  }

  .pack_meds_logo {
    flex: unset;
    margin-left: 0;
  }

  .pack_meds_logo img {
    width: 130px;
  }

  .pack_meds_title {
    margin: auto 0;
  }

  .pack_meds_title h1 {
    font-size: 24px;
  }

  .pack_meds_title p {
    font-size: 18px;
  }

  .order_meds_bg {
    background-position: top;
    background-size: 100%;
    margin: 60px 0 10px 0;
  }

  .order_meds_cont {
    padding: 72% 20px 20px 20px;
    width: 100%;
  }

  .order_meds_cont h2 {
    font-size: 24px;
  }

  .pre_packed_meds_bg {
    background-position: top;
    background-size: 100%;
    margin-bottom: 10px;
  }

  .pre_packed_meds_cont {
    width: 100%;
    padding: 64% 20px 1px 20px;
  }

  .pre_packed_meds_cont h2 {
    font-size: 24px;
  }

  .pre_packed_meds_cont p {
    font-size: 17px;
  }

  .collect_packed_meds_bg {
    background-position: top;
    background-size: 100%;
    margin-bottom: 10px;
  }

  .collect_packed_meds_cont {
    width: 100%;
    padding: 63% 20px 20px 20px;
  }

  .collect_packed_meds_cont h2 {
    font-size: 24px;
  }

  .collect_packed_meds_cont p {
    font-size: 17px;
  }

  .pack_meds_form {
    align-items: initial;
  }

  .pack_meds_form form {
    width: 100%;
  }

  .summary_of_how_it_works h4 {
    font-size: 24px;
  }

  .subscribe_for_pack_meds h4 {
    font-size: 24px;
  }

  .pack_meds_form h4 {
    font-size: 24px;
  }
}
