.marker {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #25ad19;
    height: 30px;
    width: 30px;
    font-size: 15px;
    color: #fff;
    user-select: none;
    padding-top: 4px;
    border: 1px solid #fff;
    border-radius: 100%;
    cursor: pointer;
}