.mainHeader {
  background-image: url("../../assets/images/blood_pressure.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center -100px;
  background-attachment: fixed;
}

@media (max-width: 1300px) {
  .mainHeader {
    background-attachment: scroll;
  }
}

@media (max-width: 992px) {
  .mainHeader {
    background-size: 100%;
  }
}

@media (max-width: 600px) {
  .mainHeader {
    background-size: 120%;
    background-position: center;
    background-attachment: scroll;
  }
}

.b_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), transparent),
    url("../../assets/images/blood_pressure.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.b_header {
  padding: 20% 0;
  text-align: center;
}

.b_header h1 {
  font-weight: 700;
  color: #fff;
  font-size: 62px;
  margin-bottom: 0;
}

.b_header p {
  font-size: 20px;
  color: #fff;
  font-weight: 300;
}

.b_cont {
  padding: 30px 0;
}

.b_cont h4 {
  font-size: 32px;
  color: #333;
  font-weight: 500;
}

.b_cont p {
  font-size: 17px;
  color: #222;
}

@media (max-width: 540px) {
  .b_header h1 {
    font-size: 30px;
  }

  .b_cont h4 {
    font-size: 24px;
  }
}
