.shipping_help_bg {
    background-color: #fff;
    padding: 0;
}

.shipping_help_header {
    padding: 20px 10px;
    text-align: center;
}

.shipping_help_header span {
    font-size: 20px;
    color: #999;
}

.shipping_help_header h1 {
    font-size: 34px;
    font-weight: 600;
    color: #333;
    padding-bottom: 20px;
}

.shipping_help_header button {
    background-color: #444;
    color: #fff;
    font-size: 17px;
    padding: 8px 20px;
    border: 1px solid #5553;
    border-radius: 40px;
    position: relative;
    z-index: 3;
}

.shipping_help p {
    font-size: 17px;
}

.shipping_help sup {
    font-size: 12px;
}

/* #live_chat_msg {
    display: none;
    background-color: #099;
    color: #fff;
    padding: 25px;
    position: fixed;
    right: 30px;
    border-radius: 1000px;
    cursor: pointer;
} */

/* #live_chat_msg i {
   font-size: 52px;
 }

#live_chat_form {
    display: none;
} */

.shipping_chat_form {
    background-color: #fff;
    position: fixed;
    top: 100px;
    right: 200px;
    z-index: 3;
    width: 350px;
    height: 400px;
    border-radius: 3px;
    box-shadow: 2px 2px 10px #5554;
}

.shipping_chat_form_header span#shipping_close_btn {
    position: absolute;
    cursor: pointer;
}

.shipping_chat_form_header svg {
    fill: #fff;
    width: 25px;
    height: 25px;
}

.shipping_chat_form_header span#shipping_close_btn {
    padding: 10px;
    right: 5px;
    top: -2px;
}

.shipping_chat_form_header i {
    font-size: 22px;
    color: #fff;
}

.shipping_chat_form_header h4 {
    background-color: #099;
    font-size: 20px;
    color: #fff;
    padding: 15px;
    text-align: center;
}

.shipping_chat_form form {
    background-color: #fff;
    margin-top: -10px;
    padding: 20px;
}

.shipping_chat_form p {
    font-size: 15px;
    color: #555;
}

.shipping_chat_form label {
    display: block;
    margin: auto;
    font-size: 17px;
    font-weight: 600;
}

.shipping_chat_form span {
    font-weight: normal;
    padding: 5px;
}

.shipping_chat_form input[type = text] {
    width: 100%;
    border: 1px solid #4443;
    border-radius: 3px;
    padding: 8px 20px;
    margin-bottom: 25px;
}

.shipping_chat_form button {
    background-color: #099;
    width: 100%;
    color: #fff;
    font-size: 17px;
    border: 1px solid #4443;
    border-radius: 3px;
    padding: 8px 30px;
}


.shipping_help {
    background-color: #f8f8f8;
    padding: 10px;
    margin-bottom: 20px;
}

.shipping_help h4 {
    color: #333;
    font-size: 32px;
    padding: 20px 0px;
    margin: auto;
}

.faq_question {
    display: flex;
    padding: 10px;
    cursor: pointer;
}

.faq_question p {
    background-color: #fff;
    flex: 1;
    color: #2b4b9b;
    font-weight: 600;
}

.faq_question i.angle_down {
    font-size: 20px !important;
    color: #333;
    height: 15px;
    padding: 1px;
    transition: all 0.2s ease-in-out;
}

.faq_angle_rotate {
    transform: rotateX(-180deg);
    transition: all 0.2s ease-in-out;
}

/* .faq_answer {
    display: none;
    background-color: #f7f7f7;
    padding: 10px 0;
    margin: 20px 0;
} */

.faq_answer p {
    font-size: 16px;
    color: #333;
}

.faq_answer a {
    text-decoration: none;
    font-size: 16px;
    color: #2b4b9b;
}

.faq_answer a:hover {
    text-decoration: none;
}

.faq_answer li {
    font-size: 16px;
    color: #222;
}

.faq_answer td {
    font-size: 16px;
    color: #222;
    padding: 0 10px 0 0;
}




@media(min-width: 200px) and (max-width: 768px) {
    .shipping_help_header {
        padding: 10px;
        text-align: center;
    }

    .shipping_help_header h1 {
        font-size: 25px;
        color: #222;
        font-weight: 500;
        padding-bottom: 10px;
    }

    .shipping_help {
        background-color: #f8f8f8;
        padding: 10px;
        margin: 0px 0px;
    }

    .shipping_help h4 {
        color: #333;
        font-size: 24px;
        margin: auto;
    }

    .shipping_chat_form {
        top: 0;
        right: 10px;
        left: 0;
        z-index: 3;
        height: 100%;
        width: 100%;
        height: 400px;
        border-radius: 3px;
        box-shadow: 2px 2px 10px #5554;
    }

    #live_chat_msg {
        top: 100px;
        right: 30px;
        z-index: 2;
        border-radius: 1000px;
        cursor: pointer;
    }

    span.faq_question i.angle_down {
        font-size: 20px !important;
        color: #444;
        position: absolute;
        right: 35px;
        padding-top: 5px;
        transition: all 0.2s ease-in-out;
    }
    
}

