.h_bg {
  background-image: url("../../assets/images/fertility.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  height: 400px;
  margin: 30px 0;
  border-radius: 10px;
}

.h_nav {
  background-color: #f1f1f0;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
}

.h_nav a {
  font-size: 15px;
  display: block;
}

.h_header {
  padding: 10px;
}

.h_header h1 {
  font-size: 34px;
  font-weight: 700;
  color: #222;
}

.h_header p {
  font-size: 17px;
  font-weight: 500;
  color: #333;
}
