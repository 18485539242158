.corp_header_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), transparent),
    url("../../assets/images/corporate_1.jpg");
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}

.corp_header {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2567);
}

.corp_header h1 {
  padding-top: 30%;
  font-family: "Hind", sans-serif;
  color: #fff;
  font-size: 34px;
  font-weight: 600;
  width: 45%;
}

.corp_cont h4 {
  color: #444;
  font-weight: 600;
  font-size: 35px;
  padding: 20px 0px;
}

.corp_cont p {
  font-size: 17px;
  color: #555;
}

.corp_nav {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px 0;
  margin-top: 30px;
}

.corp_nav a {
  text-decoration: none;
  color: #444;
  font-size: 15px;
  display: inline-block;
  margin-right: 10px;
}

.corp_nav span {
  font-size: 12px;
  display: inline-block;
  margin-left: 2px;
}

.corp_nav a:hover {
  text-decoration: none;
  color: #444;
}

@media (max-width: 900px) {
  .corp_header h1 {
    padding-top: 25%;
    width: 70%;
  }
}

@media (max-width: 850px) {
  .corp_header h1 {
    padding-top: 27%;
    font-size: 20px;
    width: 67%;
  }
}

@media (max-width: 400px) {
  .corp_header {
    padding: 10px;
  }

  .corp_header h1 {
    padding-top: 5%;
    font-size: 20px;
    width: 80%;
  }

  .corp_cont h4 {
    color: #444;
    font-weight: 600;
    font-size: 35px;
    padding: 20px 0px;
  }

  .corp_cont p {
    font-size: 17px;
    color: #555;
  }
}
