.header_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), transparent),
    url("../../assets/images/mediclinic_1.jpg");
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}

.header_color {
  background-color: #5152445e;
  height: 720px;
}

.header_cont {
  padding-top: 20%;
}

.header_cont h1 {
  color: #fff;
  font-size: 35px;
  font-weight: 600;
  width: 48%;
}

.pharmacy_cont {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pharmacy_cont h4 {
  font-size: 32px;
  color: #444;
  font-weight: 600;
  padding: 10px 0;
}

.pharmacy_cont p {
  font-size: 16px;
  color: #333;
}

.pharmacy_nav {
  background: rgba(0, 0, 0, 0.05);
  padding: 10px 0;
}

.pharmacy_nav a {
  text-decoration: none;
  font-size: 16px;
  color: #333;
  display: inline-block;
  margin-right: 10px;
}

.pharmacy_nav a:hover {
  text-decoration: none;
  color: #333;
}

.pharmacy_nav span {
  font-size: 12px;
  color: #444;
}

@media (max-width: 1300px) {
  .header_color {
    height: 520px;
  }

  .header_cont {
    padding-top: 30%;
  }
}

@media (max-width: 992px) {
  .header_color {
    height: 300px;
  }

  .header_cont {
    padding-top: 15%;
  }

  .header_cont h1 {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    width: 60%;
  }
}

@media (max-width: 600px) {
  .header_color {
    height: 240px;
  }

  .header_cont {
    padding-top: 20%;
  }

  .header_cont h1 {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    width: 60%;
  }
}

@media (max-width: 400px) {
  .header_color {
    height: 168px;
  }

  .header_cont {
    padding-top: 20%;
  }

  .header_cont h1 {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
  }
}
