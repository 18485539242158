.personalinfo {
  background-color: #f8f8f8;
  font-size: 18px;
  padding: 25px;
  margin-left: auto;
  margin-right: auto;
}

.button {
  border-radius: 100px;
  padding: 3px 35px;
}

@media screen and (max-width: 320px){
  .header {
    font-size: 20px;
    font-weight: bolder;
  }

  .label {
    font-size: 16px;
    font-weight: bolder;
  }
}

@media screen and (max-width: 500px){
  .button {
    width: 100%;
  }
}
