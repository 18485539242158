.deals_and_offers_nav {
  padding: 10px;
}

.deals_and_offers_nav a {
  text-decoration: none;
  font-size: 16px;
  color: #284b9b;
}

.deals_and_offers_nav span {
  display: inline-block;
  margin: 0 5px;
  font-size: 14px;
  color: #444;
}

.deals_and_offers_nav a:hover {
  text-decoration: none;
}

.deals_and_offers_nav svg {
  width: 20px;
  height: 20px;
  fill: #635b5b;
}

.deals_and_offers_nav h2 {
  font-size: 24px;
  font-weight: 600;
}

.deals_header_wrapper {
  padding: 10px;
}

.deals_header {
  text-align: center;
  padding: 40px 10px;
  margin-top: 60px;
}

.deals_header h1 {
  font-size: 52px;
  color: #333;
  font-weight: 600;
}

.deals_header p {
  font-size: 20px;
}

.gift_card_wrapper {
  padding: 20px;
}

.gift_icon span,
.gift_icon i {
  display: block;
  text-align: center;
}

div.gift_icon a {
  text-decoration: none;
}

.gift_icon i {
  font-size: 52px;
  padding-bottom: 25px;
  color: #add036;
}

.gift_icon span {
  font-size: 18px;
  color: rgb(49, 121, 80);
}

div.gift_icon:hover {
  background-color: #dd05;
  border-radius: 10px;
}

.gift_header {
  background-color: #dd0;
  padding: 20px;
  border-radius: 10px;
  margin: 0 10px 30px 10px;
  text-align: center;
}

.gift_header h4 {
  font-size: 52px;
  font-weight: 600;
}

.gift_header p {
  font-size: 17px;
  color: #222;
}

.shop_link_pharmacy_cont_wrapper {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.shop_link_pharmacy_cont_wrapper img {
  width: 50px;
}

.shop_link_pharmacy_cont {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  height: 140px;
}

.shop_link_pharmacy_cont h5 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  padding: 20px 0;
}

.gift_products_main {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;
  padding: 10px;
  margin-top: 5%;
}

.gift_products_main img {
  width: 170px;
}

.gift_products_main a {
  display: flex;
  flex-direction: row;
  height: 310px;
  text-decoration: none;
  margin: 10px;
}

.gift_products_main a:hover {
  text-decoration: none;
}

.gift_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  width: 260px;
  height: 300px;
  overflow: hidden;
}

.gift_img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 240px;
  width: 280px;
}

.gift_img img {
  max-width: 300px;
  object-fit: contain;
}

.gift_cont {
  background-color: #fff;
  padding: 10px;
  text-align: center;
}

.gift_cont h5 {
  color: #222;
  font-weight: 600;
  font-size: 20px;
}

.gift_cont p {
  font-size: 17px;
  color: #333;
}

.gift_cont span {
  font-size: 15px;
  color: #023;
}

.promo_section_wrapper {
  background-image: url("../../assets/images/delivery.jpg");
  background-position: cover;
  background-size: 100%;
  background-repeat: no-repeat;
}

.promo_section_bg {
  padding: 10px;
  margin-bottom: 10px;
}

.promo_img {
  padding: 30px 60px;
}

.promo_img img {
  width: 300px;
  height: 300px;
  border-radius: 100%;
}

.promo_form {
  padding: 10px;
}

.promo_form h4 {
  font-family: "Hind", sans-serif;
  font-weight: 600;
  font-size: 42px;
  color: #fff;
  margin: auto;
  padding-top: 80px;
}

.promo_form a {
  display: inline-block;
  background-color: rgb(160, 212, 39);
  color: #fff;
  padding: 10px 20px;
  font-size: 20px;
  border: 1px solid #5552;
  border-radius: 3px;
  margin: 5px 0px;
}

.promo_form a:hover {
  text-decoration: none;
  color: #fff;
  opacity: 0.8;
}

@media (min-width: 200px) and (max-width: 768px) {
  .deals_and_offers_nav h2 {
    font-size: 19px;
  }

  .deals_and_offers_nav a {
    display: none;
    font-size: 24px;
    font-weight: 600;
  }

  .deals_header {
    padding: 30px 10px 10px 10px;
  }

  .deals_header h1 {
    font-size: 32px;
  }

  .deals_header p {
    font-size: 17px;
  }

  div.gift_icon a {
    text-decoration: none;
  }

  .gift_icon i {
    font-size: 42px;
    padding-bottom: 10px;
  }

  .gift_icon span {
    font-size: 15px;
    margin: 10px;
  }

  div.gift_icon:hover {
    background-color: #dd05;
    border-radius: 10px;
  }

  .gift_header {
    background-color: #dd0;
    padding: 17px 10px 10px 10px;
    border-radius: 10px;
    margin: 20px 5px;
  }

  .gift_header h4 {
    font-size: 32px;
    font-weight: 600;
  }

  .gift_header p {
    font-size: 17px;
    color: #222;
  }

  .gift_products_main {
    padding: 10px;
    margin-top: 1%;
    text-align: center;
  }

  .gift_cont {
    background-color: #fff;
    padding: 10px;
  }

  .gift_cont h5 {
    color: #222;
    font-weight: 600;
    font-size: 20px;
  }

  .gift_cont p {
    font-size: 17px;
    color: #333;
  }

  .gift_cont span {
    font-size: 15px;
    color: #023;
  }

  /* .promo_section_wrapper {
        background-image: url('../assets/images/Delivery.jpg');
        background-position: cover;
        background-size: 100%;
        background-repeat: no-repeat;
    } */

  .promo_img {
    padding: 40px 0;
    text-align: center;
  }

  .promo_img img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
  }

  .promo_form {
    background-color: #fff;
    padding: 10px;
  }

  .promo_form h4 {
    font-family: "Hind", sans-serif;
    font-size: 25px;
    color: #333;
    margin: auto;
    padding: 10px;
  }
  .promo_form form {
    padding: 5px;
    margin: 0;
  }

  .promo_form input[type="text"],
  .promo_form input[type="email"] {
    width: 100%;
    padding: 8px;
    font-size: 17px;
    border: 1px solid #5552;
    border-radius: 3px;
  }

  .promo_form label {
    display: none;
  }

  .promo_form input[type="submit"] {
    background-color: rgb(160, 212, 39);
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    border: 1px solid #5552;
    border-radius: 3px;
    margin: 5px 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .gift_cont {
    background-color: #fff;
    padding: 10px;
  }

  .gift_cont h5 {
    color: #222;
    font-weight: 600;
    font-size: 20px;
  }

  .gift_cont p {
    font-size: 17px;
    color: #333;
  }

  .gift_cont span {
    font-size: 15px;
    color: #023;
  }
}
