.checkout {
    padding: 30px;
}

.contactInformation, .deliveryMethod {
    border: 1px solid rgba(0, 0, 0, 0.0);
    border-radius: 5px;
    padding: 1rem 0.5rem;
    margin: 25px 0;
    background-color: #fff;
}

.btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none !important;
}

.selectWrapper {
    position: relative;
}

.selectWrapper::after {
    content: "▼";
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.6);
    top: 19px;
    right: 15px;
    position: absolute;
}

.select, .branchSelect {
    width: 100%;
    height: 60px;
    border-radius: 7px;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    border: 1px solid rgb(133, 133, 133);
    border-image: initial;
    font-size: 14px;
    margin-bottom: 7px;
}

.select option {
    margin-top: 3px;
    border-radius: 7px;
    margin-bottom: 3px;
}

.select:focus, .branchSelect:focus {
    outline: none;
}

.branchSelect {
    border-radius: 4px;
    height: 40px;
}

.branchSelectWrapper {
    position: relative;
}

.branchSelectWrapper::after {
    content: "▼";
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.6);
    top: 10px;
    right: 15px;
    position: absolute;
}

.orderSummary {
    width: 40%;
    padding-top: 5px;
    padding-bottom: 30px;
    padding-left: 30px;
}

.dialogtextfield {
    width: 400px;
}

.cartItem {
    object-fit: fill;
    -o-object-fit: fill;
    height: 60px;
    display: block;
}

.cartItemContainer {
    border: 1px solid #ececec;
    background-color: white;
    border-radius: 5px;
    padding: 22px 8px;
    width: 90px;
    height: 90px;
    margin: 5px;
    position: relative;
}

.cartItemContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    -o-object-fit: contain;
}

.quantity {
    position: absolute;
    background-color: #c4d600;
    color: white;
    padding: 2px 5px;
    border-bottom-right-radius: 4px;
    bottom: 0;
    right: 0;
}

@media screen and (max-width: 768px) {
    .orderSummary {
        width: 100%;
        padding-left: 12px;
    }
}

@media screen and (max-width: 500px) {
    .checkout {
        padding: 15px;
    }

    .dialogtextfield {
        width: 100%;
    }

}
