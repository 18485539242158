.title {
  color: black;
  font-size: 35px;
  font-weight: 500;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  a {
    font-size: 30px;
    color: black;


    :hover {
      transition: 100ms ease-in;
      color: lighten(black, 50%);
    }
  }
}