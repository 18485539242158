.mainHeader {
  background-image: url("../../assets/images/doc.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center -100px;
  background-attachment: fixed;
}

@media (max-width: 1300px) {
  .mainHeader {
    background-attachment: scroll;
  }
}

@media (max-width: 992px) {
  .mainHeader {
    background-size: 100%;
  }
}

@media (max-width: 600px) {
  .mainHeader {
    background-size: 120%;
    background-position: center;
    background-attachment: scroll;
  }
}

.check_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), transparent),
    url("../../assets/images/doc.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.check_header {
  padding: 20px;
  text-align: center;
  padding: 30% 0;
}

.check_header h1 {
  font-size: 42px;
  font-weight: 700;
  color: #fff;
}

.check_header a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #c4d600;
  color: #fff;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 600;
}

.how_header {
  padding: 40px 0;
  text-align: center;
}

.how_header h4 {
  font-size: 32px;
  color: #333;
}

.how_flex_cont {
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

.how_1 {
  text-align: center;
  width: 350px;
}

.how_1 h5 {
  font-size: 32px;
  font-weight: 600;
  color: #333;
}

.how_1 p {
  font-size: 18px;
  color: #333;
}

.how_1 b {
  color: #777;
  font-weight: 500;
}

.consult {
  padding: 10px;
}

.consult_header {
  padding: 10px 0;
}

.consult_header h4 {
  font-size: 32px;
  margin: auto;
  color: #333;
}

.consult_header p {
  font-size: 24px;
  font-weight: 600;
  color: #444;
}

.med_c {
  padding: 10px 0;
  text-align: center;
}

.med_c h4 {
  font-size: 20px;
  color: #333;
}

.med_c p {
  font-size: 17px;
  color: #222;
}

.what_cont {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  justify-content: space-between;
}

.what_img img {
  width: 400px;
  margin-right: 20px;
}

.what_text {
  background-color: #fff;
  padding: 10px;
}

.what_text h4 {
  font-size: 20px;
  color: #111;
}

.what_text p {
  font-size: 17px;
  color: #000;
}

.could {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
}

.could_text h4 {
  font-size: 20px;
  color: #111;
}

.could_text p {
  font-size: 17px;
  color: #000;
}

.could_img img {
  width: 400px;
  margin-left: 20px;
}

@media (max-width: 700px) {
  .check_header {
    padding: 20px;
    padding: 15% 0;
  }

  .check_header h1 {
    font-size: 24px;
    font-weight: 600;
  }

  .check_header a {
    padding: 10px 20px;
    font-size: 18px;
  }

  .how_header {
    padding: 10px 0;
  }

  .how_header h4 {
    font-size: 32px;
    color: #333;
  }

  .how_flex_cont {
    padding: 10px;
  }

  .how_1 {
    width: 100%;
  }

  .how_1 h5 {
    font-size: 32px;
  }

  .consult_header h4 {
    font-size: 24px;
  }

  .what_cont {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
  }

  .what_img img {
    width: 100%;
    margin-right: 0;
  }

  .what_text {
    padding: 10px;
  }

  .could {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .could_img img {
    width: 100%;
  }
}
