.mainHeader {
  background-image: url("../../assets/images/job_4.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center -100px;
  background-attachment: fixed;
}

@media (max-width: 1300px) {
  .mainHeader {
    background-attachment: scroll;
  }
}

@media (max-width: 992px) {
  .mainHeader {
    background-size: 100%;
  }
}

@media (max-width: 600px) {
  .mainHeader {
    background-size: 120%;
    background-position: center;
    background-attachment: scroll;
  }
}

.career_header_bg {
  background-image: url("../../assets/images/store_2.jpg");
  background-size: 100%;
  background-color: #c4d600;
  background-position: top;
  background-repeat: no-repeat;
}

.career_header_color {
  background-color: #5152445e;
  padding-top: 40%;
}

.career_header h1 {
  color: #fff;
  font-size: 35px;
  font-weight: 600;
  width: 48%;
  margin-left: 40px;
}

.find_jobs {
  padding: 30px;
  background-color: #f8f8f8;
}

.find_jobs h4 {
  color: #444;
  font-size: 35px;
  font-weight: 600;
  padding-top: 40px;
}

.find_jobs h3 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  padding-bottom: 10px;
}

.find_jobs p {
  font-size: 16px;
  color: #555;
  padding: 10px 0px;
}

.search_flex {
  display: flex;
  flex-wrap: row;
  padding: 10px;
  border-bottom: 1px solid #5555;
}

.search_flex img {
  max-width: 100%;
}

.search_by_location {
  padding-top: 30px;
  width: 50%;
}

.search_by_location a {
  display: inline-block;
  margin-top: 20px;
  background-color: #c4d600;
  padding: 11px 20px;
  font-size: 17px;
  color: #fff;
  border-radius: 2px;
}

.search_by_location a:hover {
  text-decoration: none;
  color: #fff;
  opacity: 0.8;
}

.explore_op_container {
  border: 1px solid #4441;
  border-radius: 3px;
  padding: 10px;
  margin: 20px 0px;
}

.explore_header h4 {
  font-size: 34px;
  color: #445;
  font-weight: 600;
}

.explore_op_header h4 {
  font-size: 24px;
}

.explore_content_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  margin: 20px 0;
}

.explore_content_wrapper h5 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}

.explore_content_wrapper a {
  text-decoration: none !important;
}

.explore_content_wrapper a:hover {
  text-decoration: none !important;
  opacity: 0.8;
}

.explore_content_1,
.explore_content_2,
.explore_content_3,
.explore_content_4 {
  color: #fff;
  padding-top: 50px;
  padding-left: 10px;
  height: 290px;
  border: 1px solid #4441;
  border-radius: 5px;
  margin: 10px;
  width: 284px;
}

.explore_content_1 p,
.explore_content_2 p,
.explore_content_3 p,
.explore_content_4 p {
  font-size: 17px;
  padding-top: 10px;
}

.explore_content_1 svg,
.explore_content_2 svg,
.explore_content_3 svg,
.explore_content_4 svg {
  width: 65px;
  height: 65px;
  fill: #fff;
}

.explore_content_1 {
  background-color: #2880e4;
}

.explore_content_2 {
  background-color: #20b84d;
}

.explore_content_3 {
  background-color: #2880e4;
}

.explore_content_4 {
  background-color: #20b84d;
}

@media (max-width: 800px) {
  .career_header h1 {
    font-size: 24px;
    font-weight: 500;
    width: 100%;
    margin-left: 0px;
  }
  .find_jobs {
    padding: 0;
  }

  .find_jobs h4 {
    font-size: 24px;
    padding-top: 10px;
  }

  .find_jobs h3 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    padding-bottom: 10px;
  }

  .find_jobs p {
    font-size: 16px;
    color: #555;
    padding: 10px 0px;
  }

  .search_flex {
    flex-direction: row;
    padding: 10px;
  }

  .search_flex img {
    max-width: 100%;
  }

  .search_by_location {
    padding-top: 0;
    width: 100%;
  }

  .search_by_location a {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 17px;
  }
}

@media (max-width: 600px) {
  .career_header h1 {
    font-size: 24px;
    font-weight: 500;
    width: 100%;
    margin-left: 0px;
  }

  .search_flex {
    flex-direction: column;
    padding: 10px;
  }

  .explore_content_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin: 20px 0;
  }

  .explore_header h4 {
    font-size: 24px;
    padding-top: 10px;
  }

  .explore_content_wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .explore_content_1,
  .explore_content_2,
  .explore_content_3,
  .explore_content_4 {
    width: 240px;
  }
}

@media (max-width: 300px) {
  .find_jobs {
    padding: 0;
  }

  .find_jobs h4 {
    font-size: 24px;
    padding-top: 10px;
  }

  .find_jobs h3 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    padding-bottom: 10px;
  }

  .find_jobs p {
    font-size: 16px;
    color: #555;
    padding: 10px 0px;
  }
  .career_header_bg {
    height: 200px;
  }

  .career_header h1 {
    font-size: 24px;
    font-weight: 500;
    width: 100%;
    margin-left: 0px;
  }

  .search_flex {
    flex-direction: column;
    padding: 10px;
  }

  .search_flex img {
    max-width: 100%;
  }

  .search_by_location {
    padding-top: 0;
    width: 100%;
  }

  .search_by_location a {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 17px;
  }

  .explore_content_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin: 20px 0;
  }

  .explore_header h4 {
    font-size: 24px;
    padding-top: 10px;
  }

  .explore_content_wrapper {
    flex-direction: column;
  }

  .explore_content_1,
  .explore_content_2,
  .explore_content_3,
  .explore_content_4 {
    padding-top: 50px;
    padding-left: 10px;
    margin: 10px;
    width: 240px;
  }
}
