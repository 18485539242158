.mainHeader {
  background-image: url("../../assets/images/smiling_family_1.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

@media (max-width: 1300px) {
  .mainHeader {
    background-attachment: scroll;
  }
}

@media (max-width: 992px) {
  .mainHeader {
    background-size: 100%;
  }
}

@media (max-width: 600px) {
  .mainHeader {
    background-size: 100%;
    background-position: center;
    background-attachment: scroll;
  }
}


.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), transparent), url("../../assets/images/smiling_family_1.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero h1 {
  color: white;
  font-weight: bold;
  text-align: center;
}

.insuranceCompanies {
  margin-block: 2rem;
}

.insuranceImg {
  width: 125px;
  height: 125px;
}
.insuranceImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/*# sourceMappingURL=InsuranceCoverage.module.css.map */
