.logo {
  position: relative;
}

.logo a {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo svg {
  width: 55px;
  height: 40px;
  margin-right: 5px;
  font-family: "Open Sans", sans-serif;
}

.text_section {
  white-space: nowrap;
  color: black;
  align-self: flex-start;
}

.text_section span {
  display: block;
  line-height: 1;
}

.text_section span:first-child {
  margin-bottom: 2px;
  font-size: 13px;
}

.text_section span:nth-child(2) {
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.dropDown {
  position: absolute;
  top: 100%;
  background-color: #f8f8f8;
  width: 325px;
  z-index: 2000;
  overflow: hidden;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.dropDownArrow {
  width: 25px;
  height: 25px;
  background-color: #f8f8f8;

  position: absolute;
  clip-path: polygon(50% 50%, 0 100%, 100% 100%);
  margin-left: 20px;
  bottom: 0;
}

.sticky {
  position: fixed;
  top: 0;
}

.dropDownHeader {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  color: #2d2d2d;
}

.dropDownSubtotal {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #2d2d2d;
}

.dropDownItems {
  overflow-y: auto;
  max-height: 50vh;
}

.dropDownItem {
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-column-gap: 10px;
  background-color: white;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.dropDownItem .textContainer a p:hover {
  color: #c4d600;
}

.deletedIndicator {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c4d600;
  color: #f8f8f8;
  font-weight: 700;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
}

.imageContainer {
  width: 100px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.textContainer p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  color: #555;
  transition: 100ms ease-in;
}

.textContainer span {
  display: flex;
  align-items: center;
  line-height: 20px;
  justify-content: space-between;
}

.textContainer span p {
  margin: 0;
}

.textContainer h4 {
  font-size: 14px;
  font-weight: 700;
  color: #2d2d2d;
}

.trashIcon {
  cursor: pointer;
  transition: 100ms ease-in;
  color: #555;
  font-size: 16px;
}

.trashIcon:hover {
  color: red;
}

.buttonsContainer {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.buttonsContainer a {
  background-color: #c4d600;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  width: 140px;
  text-align: center;
  font-weight: 700;
}

.buttonsContainer a:hover {
  color: white;
}

.freeTextContainer {
  border-top: 1px solid #ccc;
  padding: 1rem;
  text-align: center;
}

.freeTextContainer p {
  color: black;
  margin-bottom: 0;
}

.freeTextContainer small {
  color: #555;
}

.freeTextContainer small a {
  text-decoration: underline;
  color: #b5c500;
}

@media screen and (max-width: 1627px) {
  .dropDown {
    right: 120px;
  }

  .dropDownItems {
    max-height: 300px;
  }
}

@media screen and (max-width: 600px) {
  .logo svg {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .text_section span:first-child {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .text_section span:nth-child(2) {
    font-size: 15px;
  }
}
