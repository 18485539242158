.prescriptionStatus {
    background-color: #f8f8f8;
    font-size: 18px;
}

.header {
    font-weight: 600;
}

.icons {
    background-color: #d9d5d5;
    padding: 6px;
    border-radius: 200px;
}

@media screen and (max-width: 400px) {
    .header {
        font-size: 19px;
    }

    .id {
        font-size: 13px;
    }
}
