.p_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), transparent),
    url("../../assets/images/happy_couple.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.p_header {
  text-align: center;
  padding: 15% 0;
}

.p_header h1 {
  font-size: 52px;
  font-weight: 700;
  color: #fff;
}

.p_cont {
  padding: 30px 0;
}

.p_cont h4 {
  font-size: 32px;
  font-weight: 600;
  color: #333;
}

.p_cont p {
  font-size: 17px;
  color: #111;
}

@media (max-width: 500px) {
  .p_header h1 {
    font-size: 32px;
    font-weight: 600;
  }

  .p_cont {
    padding: 15px 0;
  }

  .p_cont h4 {
    font-size: 28px;
  }
}
