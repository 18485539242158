.shipping_left{
    background-color: #f0f0f0;
    width: 100%;
    min-height: 200px;
    border-radius: 5px;
    padding: 15px;
}

.shipping_left ul{
    padding: 0 !important;
}

.shipping_left li{
    list-style-type: none;
    margin-top: 5px !important;
}

.shipping_left a{
    color: #284b9b !important;
    text-decoration: none !important;
}

@media(max-width: 767px){
    .shipping_left{
        display: none;
    }
}