.f_nav {
    padding: 24px 0;
}

.f_nav a {
    text-decoration: none;
    font-size: 16px;
}

.f_nav span {
    display: inline-block;
    margin: 0 10px;
}

.f_nav h1 {
    font-size: 24px;
    font-weight: 700;
    color: #222;
}

.f_flex {
    background-color: #f1f1f0;
    border-radius: 1px;
    display: flex;
    flex-direction: row;
    margin: 30px 0;
}

.f_flex_img {
    background-color: #f1f1f0;
    width: 425px;
}

.f_flex_img img {
    width: 400px;
}

.f_flex_cont {
    padding: 40px;
}

.f_flex_cont h2 {
    font-size: 42px;
    font-weight: 800;
    color: #333;
    margin: auto;
}

.f_flex_cont p {
    font-size: 20px;
    color: #222;
    font-weight: 500;
}

.f_bg_cont h2 {
    width: 320px;
}

.f_cont h3 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    padding: 15px 0;
}

.f_cont p, .f_cont li {
    font-size: 16px;
    color: #111;
    line-height: 1.8;
}