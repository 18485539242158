.find_jobs p {
    font-size: 17px;
    color: #555;
    padding: 10px 0px;
}

.job_search_header h3 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    padding-bottom: 10px;

}

.form_cont {
    padding-top: 30px;
}

.form_cont form {
    display: flex;
    flex-wrap: wrap;
    align-items: self-end;
    justify-content: space-between;
}

.job_form_flex {
    display: flex;
    flex-direction: column;
}

.job_form_flex label {
    font-size: 17px;
    color: #111;
}

.job_form_flex input[type=text], .job_form_flex select{
    width: 300px;
    border: 1px solid #5555;
    border-radius: 2px;
    padding: 10px;
}

.form_cont input[type=submit]{
    background-color: #c4d600;
    width: 100%;
    padding: 9px 20px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    border: 1px solid #c4d600;
    border-radius: 2px;
    cursor: pointer;
}

.form_cont input[type=submit]:hover {
    opacity: 0.7;
}

@media(max-width: 640px){
    .form_cont{
        display: none;
    }
}