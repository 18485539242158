.giftCards {
  background-color: #f8f8f8;
  font-size: 18px;
  padding: 25px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.giftCardItem {
  background: white;
  display: flex;
  padding: 1rem;
}

.card {
  padding-top: 1rem;
  width: 400px;
  height: 220px;
  border: 1px solid #add036;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.card img {
  width: 200px;
  margin-left: auto;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.strip {
  background: #add036;
  height: 50px;
  width: 100%;
}

.amountSection {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem;
  font-family: "Open Sans", sans-serif;
}

.currentValue {
  font-weight: 800;
  font-size: 12px;
  color: #555555;
}

.cardInfo {
  margin-left: 20px;
  width: 50%;
}

.redeemedAt {
  font-size: 16px;
  width: 100%;
}

.value {
  margin-bottom: 10px;
}
.value h5 {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #555555;
}

.btn {
  background-color: #add036;
  margin: 5px 0;
  padding: 0.5rem 1rem;
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  transition: 100ms ease-in;
}

.btn:hover {
  color: white;
  background-color: #beda5f;
}

@media screen and (max-width: 1053px) {
  .giftCardItem {
    flex-direction: column;
    width: 100%;
  }

  .cardInfo {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .card {
    width: 100%;
    height: 180px;
  }
}
