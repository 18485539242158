.prescriptionMedication {
    background-color: #f8f8f8;
    font-size: 18px;
}

.uploadButton, .proceedButton {
    display: inline-block;
    padding: 8px 0;
    width: 100%;
    cursor: pointer;
    background-color: #c4d600;
    color: #fff;
    border: none;
}

.roundedLeft {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.roundedAll {
    border-radius: 50px;
}

.fileUploadSecondary {
    background-color: #d1e400;
    padding: 8px 1rem 8px 1rem;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    cursor: pointer;

    color: #fff;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}


.proceedContainer {
    display: flex;
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.proceedButton:hover {
    color: #fff;
    border: none;
}

.uploadInput {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.card {
    background-color: rgba(232, 231, 231, 0.6);
    border-radius: 8px;
    padding: 20px;
    height: 100%;
}

.preview {
    position: relative;
    background-color: transparent;
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.close {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    border-radius: 100px;
    right: 5px;
    opacity: 0.7;
    cursor: pointer;
    top: 5px;
    z-index: 1;
}

.previewImage {
    width: 170px;
    height: 170px;
    object-fit: cover;
}

.guides {
    list-style: outside square;
    margin-left: -10px;

    margin-top: 0;
    margin-bottom: 1rem;
    padding-left: 2rem;
}

.pdfViewer .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}

@media screen and (max-width: 384px) {
    .proceedButton, .fileUploadSecondary {
        font-size: 14px;
    }

}
